import { centsFormat, dollarsFormat, isDollar, isPercentage } from "./PlanCostUtils.js";
import numeral from 'numeral'

/**
 Each calculator takes a single plan, and applies our complicated contribution structure to get the math you want.
 */

function applyContribution(contribution, isEquitable, individualPremium_, tieredPremium_) {
  const individualPremium = moneyNumber(individualPremium_, 2);
  const tieredPremium = moneyNumber(tieredPremium_, 2);
  // no contribution
  if (!contribution) return 0;

  if (isDollar(contribution)) {
    const rv = numeral(contribution).value();
    if (rv < 0) return 0;
    //ensure contribution does not exceed the member’s premium
    if (tieredPremium < rv) return tieredPremium;
    return rv
  } else if (isPercentage(contribution)) {
    const basis = isEquitable ? tieredPremium : individualPremium;
    const rv = numeral(contribution).multiply(basis).value();
    // shouldn't happen unless user managed to hack > 100% contribution
    if (tieredPremium < rv) return tieredPremium;
    return rv;
  } else {
    throw new Error("Invalid contribution string");
  }
}

/// returns values for the sum of premiums for the whole group
/// excludes waived members. Tiers are taken into account as necessary.
class MedicalPlanGroupCalculator {
  constructor(plan, baseContribution, splits, members) {
    this.plan = plan;
    this.baseContribution = baseContribution;
    this.splits = splits
    this.members = members

    if (!Array.isArray(this.splits)) this.splits = [];
    if (!Array.isArray(this.members)) this.members = [];
  }


  erForMembers = (members) => {
    if (members.length === 0) return 0;
    let premiums = members.map(member => {
      if (member.is_waived) return 0;
      let premium;
      this.splits.forEach(split => {
        if (!split.members.includes(member.id)) return;

        premium = applyContribution(
          split.contribution,
          split.isEquitable,
          this.plan.premium.employee['individual'],
          this.plan.premium.employee[member.tier]);
      })
      if (premium === undefined) {
        premium = applyContribution(
          this.baseContribution.value,
          this.baseContribution.isEquitable,
          this.plan.premium.employee['individual'],
          this.plan.premium.employee[member.tier]);
      }
      return premium;
    });
    return premiums.reduce((a, b) => a + b, 0);
  }

  er = () => this.erForMembers(this.members)
  ee = () => this.total() - this.er()

  erForTier = (tier) => this.erForMembers(this.members.filter(member => member.tier === tier))
  eeForTier = (tier) => this.totalForTier(tier) - this.erForTier(tier)

  totalForTier = (tier) => {
    const premiums = this.members.map(member => {
      if (member.tier !== tier) return 0;
      if (member.is_waived) return 0;
      return moneyNumber(this.plan.premium.employee[member.tier], 2);
    });
    return premiums.reduce((a, b) => a + b, 0);
  }

  erString = () => moneyString(this.er());
  eeString = () => moneyString(this.ee());

  total = () => {
    if (this.members.length === 0) return 0;
    const premiums = this.members.map(member => {
      if (member.is_waived) return 0;
      return moneyNumber(this.plan.premium.employee[member.tier], 2);
    });
    return premiums.reduce((a, b) => a + b, 0);
  }

  totalString = () => moneyString(this.total());

  eeCostForTier = (tier) => {
    return moneyNumber(this.plan.premium.employee[tier], 2) - this.erCostForTier(tier)
  }

  erCostForTier = (tier) => {
    let premium = applyContribution(
      this.baseContribution.value,
      this.baseContribution.isEquitable,
      this.plan.premium.employee['individual'],
      this.plan.premium.employee[tier]);

    return moneyNumber(premium, 2)
  }
}

/// returns values for the sum of premiums for the whole group
/// excludes waived members. Tiers are taken into account as necessary.
class AncillaryPlanGroupCalculator {
  constructor(plan, contribution, members) {
    this.plan = plan
    this.contribution = contribution
    this.members = members

    if (!Array.isArray(this.members)) this.members = [];
  }

  er = () => {
    if (this.contribution === undefined || this.contribution === null) this.contribution = "$0";
    if (this.members.length === 0) return 0;

    //NOTE currently waiving means waiving from EVERYTHING, yes prosper and ancillary too

    return this.members.map(member => {
      if (member.is_waived) return 0;
      const individualPremium = this.plan.rates['individual'];
      let tieredPremium = this.plan.rates[member.tier];
      if (!tieredPremium && (member.tier === 'couple')) {
        tieredPremium = this.plan.rates['couple'];
      }
      return applyContribution(this.contribution, false, individualPremium, tieredPremium);
    }).reduce((a, b) => a + b, 0);
  }

  total = () => {
    if (this.members.length === 0) return 0;
    return this.members.map(member => {
      if (member.is_waived) return 0;
      let tieredPremium = this.plan.rates[member.tier];
      if (!tieredPremium && (member.tier === 'couple')) {
        tieredPremium = this.plan.rates['couple'];
      }
      return moneyNumber(tieredPremium);
    }).reduce((a, b) => a + b, 0);
  }

  ee = () => this.total() - this.er()

  erString = () => moneyString(this.er());
  eeString = () => moneyString(this.ee());
  totalString = () => moneyString(this.total());
}

class AncillaryPlanEmployeeCalculator {
  constructor(plan, contribution, tier) {
    this.plan = plan;
    this.contribution = contribution
    this.tier = tier
  }

  er = () => applyContribution(this.contribution, false, this.plan.rates['individual'], this.plan.rates[this.tier])
  ee = () => moneyNumber(this.plan.rates[this.tier], 2) - this.er()
}

class MedicalPlanEmployeeCalculator {
  constructor(plan, baseContribution, baseContributionIsEquitable, tier, splits, memberID) {
    const individualBasis = moneyNumber(plan.premium.employee['individual'], 2);
    const basis = moneyNumber(plan.premium.employee[tier], 2);
    (splits || []).forEach(split => {
      if (!split.members.includes(memberID)) {
        return;
      }
      this.erValue = applyContribution(split.contribution, split.isEquitable, individualBasis, basis);

    })
    if (this.erValue === undefined) {
      this.erValue = applyContribution(baseContribution, baseContributionIsEquitable, individualBasis, basis);
    }
    this.eeValue = Math.max(0, basis - this.erValue);

  }
  er = () => this.erValue
  ee = () => this.eeValue
}

function moneyString(rawInput, precision = 0) {
  if (rawInput === 0) {
    return '$0';
  }
  const value = moneyNumber(rawInput, precision);
  const format = precision === 0 ? dollarsFormat : centsFormat;
  const outputStr = numeral(value).format(format);

  return outputStr;
}

function moneyNumber(rawInput, precision = 0) {
  if (rawInput === undefined || rawInput === null || rawInput === "") return 0;
  let value;
  const tenPower = Math.pow(10, precision);
  value = numeral(rawInput).multiply(tenPower).value();
  value = Math.ceil(value) / tenPower;
  return value;
}

function employerMedicalContributionforEmployee(id, splits, medical_contribution_equitable, medical_contribution) {
  if (splits && splits.length > 0) {
    const split = splits.find(split => split.members.some(member => member === id))
    if (split) {
      return { isEquitable: split.isEquitable, contribution: split.contribution }
    }
  }
  return { isEquitable: medical_contribution_equitable, contribution: medical_contribution }
}

export {
  MedicalPlanGroupCalculator,
  MedicalPlanEmployeeCalculator,
  AncillaryPlanGroupCalculator,
  AncillaryPlanEmployeeCalculator,
  moneyString,
  moneyNumber,
  employerMedicalContributionforEmployee
}
