const server = process.env.REACT_APP_PHARAOH

async function go(url, obj) {
  const rsp = await fetch(url, obj);
  if (rsp.ok) {
    const contentType = ((rsp.headers.get('Content-Type') || '').split(';')[0] || '').trim();
    if (contentType === 'application/json') {
      return rsp.json();
    } else {
      return rsp;
    };
  } else {
    const err = new Error(rsp.statusText);
    err.response = rsp;
    try {
      err.json = await rsp.json();
    } catch {
      err.json = {code: "UNKNOWN_ERROR"};
    }
    throw err;
  }
}

function defaultHeaders() {
  const headers = {
    "Accept": "application/json"
  }
  if (process.env.REACT_APP_ADD_WHITE_LABEL_HEADER === 'true') {
    headers['X-Ra-White-Label'] = process.env.REACT_APP_WHITE_LABEL
  }
  return headers
}

async function get(path) {
  const headers = defaultHeaders()
  const token = get_bearer_token()
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  return go(`${server}${path}`, {headers: headers});
}

async function delete_(path) {
  const headers = defaultHeaders()
  const token = get_bearer_token()
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  return go(`${server}${path}`, {headers: headers, method: "DELETE"});
}

async function put(path, data = []) {
  const headers = defaultHeaders()
  headers["Content-Type"] = "application/json"
  const token = get_bearer_token()
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  if (data instanceof FormData) {
    delete headers['Content-Type']
  } else if (data) {
    data = JSON.stringify(data)
  }

  return go(`${server}${path}`, {
    method: "PUT",
    headers: headers,
    body: data
  });
}

async function upload_(path, formData = new FormData()) {
  const headers = defaultHeaders()
  const token = get_bearer_token()
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return go(`${server}${path}`, {
    method: "POST",
    cache: "no-cache",
    headers: headers,
    body: formData
  });
}

async function upload(path, file) {
  const formData = new FormData()
  formData.append('file', file)
  return upload_(path, formData)
}

async function post(path, data, no_auth) {
  const headers = defaultHeaders()
  headers["Content-Type"] = "application/json"

  if (no_auth !== false) {
    const token = get_bearer_token()
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
  }

  if (data instanceof FormData) {
    delete headers['Content-Type']
  } else if (data) {
    data = JSON.stringify(data)
  }

  return go(`${process.env.REACT_APP_PHARAOH}${path}`, {
    method: "POST",
    cache: "no-cache",
    headers: headers,
    body: data
  });
}

function set_bearer_token(new_value) {
  const str = (new_value || "").trim();
  if (str) {
    localStorage[token_key_name] = new_value;
  } else {
    throw new Error("Invalid or empty token returned from server");
  }
}

function get_bearer_token() {
  const s = localStorage[token_key_name];
  if (s) {
    return s.trim();
  } else {
    return undefined;
  }
}

function has_bearer_token() {
  return localStorage[token_key_name] != null
}


//// Constants

const token_key_name = 'token';

const bearer_token_export = {
  is_valid: has_bearer_token,
  set: set_bearer_token,
  clear: function() {
    localStorage.clear(); //FIXME!
  }
};

//// Exports

export { get, put, post, delete_, bearer_token_export as token, upload, upload_ }
