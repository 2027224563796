import React, { Component } from "react";
import errorAlert from "../utils/alert";
import api from "../utils/supportApi";

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Basic_validation
// eslint-disable-next-line
const validateEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

class UpdateUserEmail extends Component {
  state = {
    email: this.props.member.email,
  };

  handleClick = () => {
    const { member } = this.props;
    let email = prompt("Enter email:");

    // cancel
    if (!email) {
      return;
    } else {
      // clean email input
      email = email.trim().toLowerCase();
    }
    if (!validateEmail.test(email))
      return errorAlert("Error! Please enter a valid email!", email);

    api
      .updateMemberEmail(member.id, email)
      .then(() => {
        alert(`Email updated to ${email}`);
        this.setState({ email });
      })
      .catch(errorAlert);
  };

  render() {
    return (
      <>
        {this.state.email}
        <button className="btn btn-primary" onClick={this.handleClick}>
          Change email
        </button>
        {this.props.member.resetPasswordLink &&
        <a href={`mailto:${this.state.email}?subject=Your%20password%20reset%20link&body=${this.props.member.resetPasswordLink}`} className="btn btn-sm btn-success" value={this.state.email}>Email Reset Link</a>
        }
      </>
    );
  }
}

export default UpdateUserEmail;
