import React, { useState } from 'react'

export default function AgeBandEditorRow({standardBand_param, standardBand_current, smokingBand_param, smokingBand_current, i, updateReturnValue}) {
    const [band, setBand] = useState(standardBand_param)
    const [smokingBand, setSmokingBand] = useState(smokingBand_param)
    const [standardDiff, setStandardDiff] = useState(0)
    const [smokingDiff, setSmokingDiff] = useState(0)

    const parseVal = val => val[0] === "$" ? parseFloat(val.toString().split('').filter(char => char !== ',').slice(1).join('')) : parseFloat(val.toString().split('').filter(char => char !== ',').join(''))
    const calcDiff = (left, right) => (Math.round((parseVal(left) - parseVal(right))*100)/100).toFixed(2)

    const handleChange = (e, type) => {
        let val =  e > 1000000 ? 1000000 : e < 0 ? 0 : e // max value of 1,000,000, min value of 0
        type === 'standard' ? setStandardDiff(calcDiff(val || 0, standardBand_current)) : setSmokingDiff(calcDiff(val || 0, smokingBand_current))
        type === 'standard' ? setBand(val) : setSmokingBand(val)
        updateReturnValue(i, type, val)
    }

    return (
        <tr style={{ display: 'flex', alignItems: 'center', border: 'none', marginTop: '7px'}}>
            <span className='age-banded-input'>Age {i}:</span>
            <input className='form-input age-banded-input' value={band} onChange={e => handleChange(e.target.value, 'standard')}/>
            <span className='age-banded-input' style={{color: `${standardDiff > 0 ? 'green' : standardDiff < 0 ? 'red' : 'lightgrey'}`}}>{standardDiff}</span>
            <input className='form-input age-banded-input' value={smokingBand} onChange={e => handleChange(e.target.value, 'smoking')}/>
            <span className='age-banded-input' style={{color: `${smokingDiff > 0 ? 'green' : smokingDiff < 0 ? 'red' : 'lightgrey'}`}}>{smokingDiff}</span>
        </tr>
    )
}
