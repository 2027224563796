import React, { Component } from 'react';
import errorAlert from 'utils/alert'
import { delete_ } from 'utils/api-primitives'

export default class GroupDumpArchive extends Component {
  state = {
    archived: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupID !== this.props.groupID) {
      this.setState({ archived: false });
    }
  }

  archive = () =>
    delete_(`/v2/groups/${this.props.groupID}`).then(() => {
      this.setState({ archived: true })
    }).catch(errorAlert)

  render = () => {
    if (!this.props.groupID) return <></>;

    const end = this.state.archived
      ? <p>Archival succeeded!</p>
      : <button className="btn btn-danger" onClick={this.archive}>Archive This Group</button>;

    return <>
      <h3>Archive Group</h3>
      <p>This will move a group to a hidden association, the group is not deleted or lost, but restoring requires asking Product really nicely.</p>
      <p>Archived groups will not show up here <i>or</i> on any production sites.</p>
      {end}
    </>
  }
}
