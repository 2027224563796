import React, { Component } from "react";

class Themer extends Component {
  state = { theme: localStorage['cairoTheme'] || 'cerulean' }
  themes = [
    'cerulean',
    'cosmo',
    'cyborg',
    'darkly',
    'journal',
    'litera',
    'lumen',
    'lux',
    'materia',
    'minty',
    'pulse',
    'sandstone',
    'simplex',
    'slate',
    'solar',
    'spacelab',
    'superhero',
    'united',
    'yeti',
  ];

  render = () => {
    return (
      <>
        <link rel='stylesheet' type='text/css' href={`https://stackpath.bootstrapcdn.com/bootswatch/4.5.2/${this.state.theme}/bootstrap.min.css`} />
        <div className="form-check form-check-inline">
          <label className="form-check-label">Theme: </label>
          {this.themes.map((theme, index) => {
            return (
            <div key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="theme"
                value={theme}
                onChange={this.handler}
                checked={theme === this.state.theme}
                key={index}
              />
              <label className="form-check-label" key={`${index}.label`}>{theme}</label>
            </div>
          )})}
        </div>
      </>
    );
  };

  handler = (event) => {
    localStorage.setItem('cairoTheme', event.target.value)
    this.setState({ theme: event.target.value })
  };
}

export default Themer;
