import React, { useState } from 'react'
import { put } from './api-primitives'
import './forms.css'

export default function SalaryForm({member, setSalary, salary, getMemberPremiums, hasSTDplan}) {
    let [mem] = member
    const [changed, setChanged] = useState(false)
    const [state, setState] = useState(salary)

    const handleChange = (e) => {
        let newSalary = parseInt(e)
        setState(parseInt(!isNaN(newSalary) ? newSalary : 0))
        setChanged(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await put(`/v3/users/${mem.id}/salary`, state)
        setSalary(state)
        await getMemberPremiums()
        setChanged(false)
    }

  return (
    <div className='form-container'>
        <h4>Update Salary for {mem.name}</h4>
            <table className='table table-responsive table-striped'>
                <tbody>
                    <div>
                        <form onSubmit={(e) => handleSubmit(e)} style={{display: 'flex', alignItems: 'center'}}>
                            <span className='form-span'>Salary</span>
                            <input className='form-input' value={state} onChange={e => handleChange(e.target.value)}/>
                            <button button className={changed ? 'btn btn-danger' : 'btn btn-light'}>update</button>
                        </form>
                    </div>
                    {hasSTDplan && <span className='warning-span'>EE HAS AN STD PLAN, CHANGING SALARY MAY REQUOTE</span>}
                </tbody>
            </table>
    </div>
  )
}
