import React, { useState } from 'react';
import TableRender from '../../utils/TableRender'
import { formatDate } from 'utils/formatters'
import { utcMidnightToLocalMidnight } from 'utils/betterDateFormatters'
import { ZohoGroupsPanel } from '../Zoho/Panel'
import { get } from 'utils/api-primitives'
import fileDownload from 'js-file-download';
import errorAlert from 'utils/alert';
import moment from 'moment'
import api from 'utils/api'
import sApi from 'utils/supportApi'
import NoyoGroup from '../Noyo/NoyoGroup'
import { useToggle } from 'react-use';
import { useForm } from "react-hook-form";

export default function GroupDumpResults(props) {
  const [disabled, setDisabled] = useState(false)
  const [effectiveDate, setEffectiveDate] = useState();

  if (!props.group) return <></>

  const groupRows = () => {
    const group = [props.group];
    const groupRows = group.map(mangleGroup)
    const keys = Object.keys(groupRows[0]);
    const rows = [keys];
    groupRows.forEach(row => rows.push(keys.map(key => row[key])));
    return rows;
  }

  const onClick = (id) => {
    window.location.search = `renew=${id}`
  }

  return <>
    <TableRender rows={groupRows()} />
    <form style={{ margin: '1rem 0' }} onSubmit={submit}>
      <fieldset disabled={disabled}>
      Update group&apos;s Effective Date.
      <small style={{ display: 'block', marginBottom: '.5rem' }}>This may affect the group&apos;s plans or eligibility</small>
      <label style={{ display: 'block' }}>
        Effective Date:
        <select className='custom-select' name='effectiveDate' placeholder='Effective Date' style={{width: 'fit-content'}} defaultValue={''} onChange={e => setEffectiveDate(e.target.value)}>
          <option value='' disabled>Select new effective date</option>
          {Array(24).fill(0).map((_, idx) => {
            const date = moment.utc().date(1).subtract(1, 'year').add(idx + 1, 'month').hours(0).minutes(0).seconds(0).milliseconds(0)
            return <option value={date.format()} key={idx}>{date.format('YYYY-MM-DD').toString()}</option>
          })}
        </select>
      </label>
      <input style={{ display: 'block' }} className='btn btn-success' type='submit' value='Submit'/>
      </fieldset>
    </form>
    <ZohoGroupsPanel {...props} />
    <br />
    <NoyoGroup {...props} />
    <br />
    <button className='btn btn-primary' onClick={() => onClick(props.group.id)}>Prep for renewal</button>
    <br />
    <button className='btn btn-primary' onClick={() => getCensus('oca',props.group.id)}>OCA Census</button>
    <button className='btn btn-primary' onClick={() => getCensus('healthAdvCensus',props.group.id)}>Health Advocate Census</button>
  </>

  async function submit(event) {
    event.preventDefault()
    try {
      setDisabled(true)
      if (!effectiveDate || !props?.group?.group?.id) return

      await api.updateGroup({
        id: props.group.group.id,
        dates: { effective: effectiveDate }
      })
      alert('Effective date has been changed. Please refresh the page to see changes (if desired).')
    } catch (error) {
      errorAlert(error)
    } finally {
      setDisabled(false)
    }
  }
}

function getCensus(censusType, groupId) {
  get(`/v3/cairo/groups/${groupId}/documentType/${censusType}`).then(rsp => rsp.blob()).then(data => fileDownload(data, `${censusType}.csv`)).catch(errorAlert)
}

function mangleGroup(group) {

  function address() {
    return [group.group.address1, group.group.address2, group.group.city].filter(o => !!o).map((o, idx) => <div key={idx}>{o}<br /></div>);
  }

  return {
    "Association Names": <ul className="list-group">{group.associationNames.map((o, idx) => <li className="list-group-item" key={idx}>{o}</li>)}</ul>,
    "White-label": group.whiteLabelCodeName,
    "Agency": group.agency,
    "Industry SIC Code": group.industrySICCode,
    "Account Name": group.name,
    "Address": address(),
    "ZIP Code": group.group.zipCode,
    "County ID": `${group.group.countyID} (${group.countyName})`,
    "FIPS Codes": `${group.fipsCodes.map(fip => ` ${fip.fips} (${fip.county})`)}`,
    "County": `${group.countyName} (${group.group.countyID})`,
    "Phone": group.phoneNumber,
    "# Employees": group.member_count,
    "Effective Date": formatDate(utcMidnightToLocalMidnight(group.effectiveDate)),
    "EIN": group.ein,
    "Existing Coverage Renewal": formatDate(utcMidnightToLocalMidnight(group.group.existingCoverageRenewalDate)),
    "Existing Coverage Carrier": group.group.existingCoverageCarrier,
    "Waiting Period": group.group.waitingPeriod || "–",
    "Completed Carrier Application": (group.group.carrierApplicationComplete || false).toString(),
    "DocuSign": group.group.envelopeId ? <a href={`https://app${process.env.REACT_APP_ENV !== 'production' ? 'demo' : ''}.docusign.com/documents/details/${group.group.envelopeId}`} target="_blank" rel="noopener noreferrer">envelope</a> : "–",
    "EEs Invites/Rates Locked": (group.ratesLocked || false).toString(),
    "ID": group.id,
    "Noyo Connection ID": <a href="#noyo_group">{group.group.noyoConnectionId}</a>,
    "Enrollment Docs Link": <EnrollmentDocs groupID={group.id} enrollmentDocsURL={group.enrollmentDocsURL} />
  }
}

const EnrollmentDocs = ({ groupID, enrollmentDocsURL }) => {
  const [editable, toggleEditable] = useToggle(false);
  const { handleSubmit, register } = useForm({ defaultValues: { groupID, enrollmentDocsURL }})

  const submit = async data => {
    try {
      await sApi.enrollmentDocsURL(groupID, data)
      alert('URL set. Reload to see updates.')
    } catch (error) {
      errorAlert(error)
    } finally {
      toggleEditable()
    }
  }

  return editable
    ? <form onSubmit={handleSubmit(submit)}>
        <input type='hidden' name='groupID' ref={register} />
        <input className='form-control' type='text' name='enrollmentDocsURL' ref={register} />
      </form>
    : <span onClick={toggleEditable}>{enrollmentDocsURL || '-'}</span>
};
