import React, { Component } from 'react'
import TableRender from '../../utils/TableRender'
import { VisionPlanEditor, DentalPlanEditor, LifePlanEditor, SupplementalPlanEditor } from '../PlanEditor'
import { VisionPlanCreator, DentalPlanCreator, LifePlanCreator } from '../PlanCreator'
import UploadSBC from 'components/UploadSBC'
import ExternalId from 'components/ExternalId'

class GroupDumpAncillaryPlans extends Component {

  state = {
    showCreatePlan: false
  }

  render() {
    const props = this.props;

    const planRows = () => {
      const planRows = props.plans.map(this.manglePlan.bind(this));
      const keys = Object.keys(planRows[0]);
      const rows = [keys];
      planRows.forEach(row => rows.push(keys.map(key => row[key])));
      return rows;
    }

    const planType = props.plans[0] !== undefined ? props.plans[0].plan.type : 'nil'
    const showAddPlan = !['accident', 'cancer', 'criticalIllness', 'hospital', 'std'].includes(planType)
    const addPlanButton = this.state.showCreatePlan
      ? null
      : <p><button className="btn btn-success" onClick={this.createPlanCallback.bind(this)}>Add New Custom Plan</button></p>;

    return <>
      {!props.plans || props.plans.length <= 0
        ? <p>—</p>
        : <TableRender rows={planRows()} />
      }
      <UploadSBC planID={this.state.uploadingSBCForPlan?.id} type={this.state.uploadingSBCForPlan?.type}/>
      {this.innerRender()}
      {showAddPlan && addPlanButton}
      <p>Note to remove the default plans, ask in Data Munging</p>
    </>
  }

  editPlanCallback(plan) {
    this.setState({editingPlan: plan})
  }

  uploadSBCCallback = (plan) => {
    this.setState({ uploadingSBCForPlan: plan })
  }

  createPlanCallback() {
    this.setState({showCreatePlan: true})
  }

  manglePlan(tuple) {
    const planType = tuple.plan.type
    const showEditButton = !['criticalIllness', 'hospital', 'std'].includes(planType)
    return {
      "Name": tuple.plan.name,
      "Edit": <>
        {showEditButton && <button className="btn btn-success" onClick={() => this.editPlanCallback(tuple)}>Edit</button>}
        <button className="btn btn-primary" onClick={() => this.uploadSBCCallback(tuple.plan)}>Upload SBC</button>
      </>,
      "ee": tuple.rates.individual || "–",
      "ee+spouse": tuple.rates.couple || "–",
      "ee+child": tuple.rates.singleParent || "–",
      "ee+family": tuple.rates.family || "–",
      "ID": tuple.plan.id,
      "Policy #": <ExternalId groupID={this.props.groupID} plan={{...tuple.plan, policyId: tuple.policyId}} />,
    }
  }
}

class GroupDumpDentalPlans extends GroupDumpAncillaryPlans {
  manglePlan(tuple) {
    const obj1 = super.manglePlan(tuple)
    const obj2 = {
      "Annual Max": (tuple.plan.data && tuple.plan.data.preventativeMaxInNetwork) || "–",
      "Deductible": (tuple.plan.data && tuple.plan.data.preventativeDeductibleInNetwork) || "–",
      "Coinsurance": (tuple.plan.data && tuple.plan.data.majorCoinsuranceInNetwork) || "–",
    };
    return {...obj1, ...obj2}
  }

  innerRender() {
    return <>
      <DentalPlanEditor {...this.state.editingPlan} groupID={this.props.groupID} />
      <DentalPlanCreator shouldRender={this.state.showCreatePlan} groupID={this.props.groupID} />
    </>
  }
}

class GroupDumpVisionPlans extends GroupDumpAncillaryPlans {
  innerRender() {
    return <>
      <VisionPlanEditor {...this.state.editingPlan} groupID={this.props.groupID} />
      <VisionPlanCreator shouldRender={this.state.showCreatePlan} groupID={this.props.groupID} />
    </>
  }
}

class GroupDumpLifePlans extends GroupDumpAncillaryPlans {
  innerRender() {
    return <>
      <LifePlanEditor {...this.state.editingPlan} groupID={this.props.groupID} />
      <LifePlanCreator shouldRender={this.state.showCreatePlan} groupID={this.props.groupID} />
    </>
  }
}

class GroupDumpSupplementalPlans extends GroupDumpAncillaryPlans {
  innerRender() {
    return <>
      <SupplementalPlanEditor {...this.state.editingPlan} groupID={this.props.groupID} />
    </>
  }
}

export { GroupDumpDentalPlans, GroupDumpVisionPlans, GroupDumpLifePlans, GroupDumpSupplementalPlans }
