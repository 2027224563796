import React, { Component } from 'react'
import api from 'utils/api'
import errorAlert from 'utils/alert'
import queryString from 'query-string'
import moment from 'moment'

class GroupRenewal extends Component {
  state = { id: '', renewal: null }

  componentDidMount() {
    const locationParams = queryString.parse(window.location.search)
    if (locationParams.renew) {
      this.setState({ id: locationParams.renew }, this.go)
    }
  }

  go = async (event = null) => {
    if (event) event.preventDefault()

    this.setState({ loading: true })
    api.getRenewalGroup(this.state.id)
      .then(renewal => this.setState({ renewal }))
      .catch(errorAlert)
      .finally(() => this.setState({ loading: false }))
  }

  handler = (event) => this.setState({ id: event.target.value })

  startRenewal = event => {
    event.preventDefault()
    api.renewGroup(this.state.id, this.state.renewal)
      .then(() => window.location.search = '')
      .catch(errorAlert)
  }

  bandHandler = (id, value, band) => {

  }

  tierHandler = (id, value, tier) => {
    const renewal = this.state.renewal
    let plans = renewal.plans.map(plan => {
      if (plan.groupPlan.id === id) {
        plan.rates[tier] = value
      }
      return plan
    })
    this.setState({ renewal: { ...renewal, plans } })
  }

  render = () => {
    const renewal = this.state.renewal
    return <>
      <form onSubmit={this.go}>
        <input className='form-control' type='text' value={this.state.id} onChange={this.handler} placeholder='Group ID' />
        <input className='btn btn-primary' type='submit' />
      </form>

      {this.state.loading && <p>Loading…</p>}
      {renewal && <form onSubmit={this.startRenewal}>
        <table className='table table-bordered table-striped table-responsive'>
          <tr><th>Group:</th><td>{renewal.group.name}</td></tr>
          <tr><th>Renewal Effective Date:</th><td>{moment.utc(renewal.group.effectiveDate).add(1, 'year').startOf('month').format('YYYY-MM-DD')}</td></tr>
          {renewal.plans.map(plan => <tr><th>{plan.name}</th><td><RenewalPlan plan={plan} bandHandler={this.bandHandler} tierHandler={this.tierHandler} /></td></tr>)}
        </table>
        <input className='btn btn-success' type='submit' value='Renew!' />
      </form>}
    </>
  }
}

const RenewalPlan = ({plan, bandHandler, tierHandler}) => {
  if (plan.ageBands) {
    return <AgeBandedPlan plan={plan} handler={bandHandler} />
  }
  return <TieredPlan plan={plan} handler={tierHandler} />
}

const AgeBandedPlan = ({plan}) => <></>

const TieredPlan = ({plan, handler}) => <table className='table table-responsive table-striped table-bordered'>
  <tr><th>EE</th><td><input className='form-control' onChange={e => handler(plan.groupPlan.id, e.target.value, 'individual')} value={plan.rates.individual} /></td></tr>
  <tr><th>CH</th><td><input className='form-control' onChange={e => handler(plan.groupPlan.id, e.target.value, 'singleParent')} value={plan.rates.singleParent} /></td></tr>
  <tr><th>SP</th><td><input className='form-control' onChange={e => handler(plan.groupPlan.id, e.target.value, 'couple')} value={plan.rates.couple} /></td></tr>
  <tr><th>FM</th><td><input className='form-control' onChange={e => handler(plan.groupPlan.id, e.target.value, 'family')} value={plan.rates.family} /></td></tr>
</table>

export default GroupRenewal
