import React from 'react'

export default function TableRender(props) {
  if (props.rows.length <= 0) return null

  const header = props.rows.shift()

  return <div className='table-responsive'>
    <table className='table table-striped table-bordered table-hover table-sm' width='100%'>
      <tbody>
        <tr>{header.map((i, idx) => <th className='text-primary' scope='col' key={idx}>{i}</th>)}</tr>
        {props.rows.map((row, idx) =>
          <tr key={idx}>{row.map((i, idx) => <td key={idx}>{i}</td>)}</tr>
        )}
      </tbody>
    </table>
  </div>
}
