import React, { useState } from 'react'
import supportApi from 'utils/supportApi'
import errorAlert from 'utils/alert'

enum ZohoCRMError {
    success = 'SUCCESS',
    invalidModule = 'INVALID_MODULE',
    invalidData = 'INVALID_DATA',
    invalidUrlPattern = 'INVALID_URL_PATTERN',
    oauthScopeMismatch = 'OAUTH_SCOPE_MISMATCH',
    noPermission = 'NO_PERMISSION',
    internalError = 'INTERNAL_ERROR',
    invalidRequestMethod = 'INVALID_REQUEST_METHOD',
    authorizationFailed = 'AUTHORIZATION_FAILED',
    mandatoryNotFound = 'MANDATORY_NOT_FOUND'
}

function apiCall(endpoint: keyof typeof supportApi, data: any, func: (state: boolean) => void): Promise<any> {
    func(true)
    return supportApi[endpoint](data).then(rsp => {
        const { responses } = rsp
        if (responses) {
            let reason = ''
            for (let resp of responses) {
                const { data } = resp
                if (data) {
                    for (let item of data) {
                        if (item.code !== ZohoCRMError.success) {
                            reason += `${item.code}: ${JSON.stringify(item.details)}\n\n`
                        }
                    }
                }
            }
            if (reason.length > 0) {
                errorAlert('Zoho Error', reason)
            }
        }
    }).catch(errorAlert).finally(() => func(false))
}

function renderButton(state: boolean, onClick: () => any, text: string) {
    return <button className='btn btn-primary' disabled={state} onClick={() => onClick()}>{state ? 'loading...' : text}</button>
}

export function ZohoGroupsPanel(props: { group: { id: string }, members: [{ id: string }] }) {
    const groupId = props.group.id
    const memberIds = props.members.map((m: {id: string}) => m.id)
    const [pullGroupState, setPullGroupState] = useState(false)
    const [pullMembersState, setPullMembersState] = useState(false)
    const [pushGroupState, setPushGroupState] = useState(false)
    const [pushMembersState, setPushMembersState] = useState(false)
    return <>
        {renderButton(pullGroupState, () => apiCall("zohoImportGroups", { groupIds: [groupId] }, setPullGroupState), 'Pull group from Zoho (if exists)')}
        {renderButton(pushGroupState, () => apiCall("zohoExportGroups", { groupIds: [groupId] }, setPushGroupState), 'Push group to Zoho')}
        <br />
        {renderButton(pullMembersState, () => apiCall("zohoImportMembers", { memberIds }, setPullMembersState), 'Pull all members from Zoho')}
        {renderButton(pushMembersState, () => apiCall("zohoExportMembers", { memberIds }, setPushMembersState), 'Push all members to Zoho')}
    </>
}

export function ZohoMembersPanel(props: { member: { id: string } }) {
    const [pullMember, setPullMember] = useState(false)
    const [pushMember, setPushMember] = useState(false)
    return <>
        {renderButton(pullMember, () => apiCall('zohoImportMembers', { memberIds: [props.member.id] }, setPullMember), 'Pull member from Zoho')}
        {renderButton(pushMember, () => apiCall('zohoExportMembers', { memberIds: [props.member.id] }, setPushMember), 'Push member to Zoho')}
    </>
}