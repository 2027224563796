import React from 'react'
import api from "utils/supportApi";
import errorAlert from "utils/alert";
import GroupDumpMember from './GroupDump.Member'

export default function GroupDumpMembers(props) {
  if (!props.members || props.members.length <= 0) return <p>—</p>

  const hasInvites = () => !!props.members.find(member => member.employeeSignUpLink)
  const disableDeleteInvitesBtn = () => {
    const status = props.group?.group?.applicationStatus
    return status && status === 'enrolled'
  }

  const uninvite = event => {
    if (event) { event.preventDefault() }
    api.uninvite(props.group.id)
      .then(() => { alert('Employee invites have been deleted. Please refresh the page to see changes (if desired).') })
      .catch(errorAlert)
  }

  return <>
    { hasInvites() &&
      <input
        className='btn btn-danger'
        type="button"
        onClick={uninvite}
        disabled={disableDeleteInvitesBtn()}
        value="Delete Group Member Invites"
      />
    }{
      props.members.map((member, index) => {
        return <GroupDumpMember member={member} dependents={member.dependents} group={props.group} plans={props.plans} ancillary={props.ancillary} splits={props.splits} key={index} />
      })
    }
  </>
}