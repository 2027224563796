/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import AgeBandEditorRow from './AgeBandEditorRow'
import { get, put } from './api-primitives'

export default function AgeBandEditor({ plan, groupID, handleCloseAgeBandsForm }) {
    const [loading, setLoading] = useState(true)
    const [standardBands, setStandardBands] = useState({})
    const [standardBands_current, setStandardBands_current] = useState({})
    const [smokingBands, setSmokingBands] = useState({})
    const [smokingBands_current, setSmokingBands_current] = useState({})
    const [changed, setChanged] = useState(false)
    const [returnValue, setReturnValue] = useState({})
    const [fetched, setFetched] = useState(false)

    useEffect(() => {
        if(fetched) handleCloseAgeBandsForm() // if you search for another group force close the age band form, otherwise it will persist
        getAgeBands()
    }, [groupID])
    
    const getAgeBands = async () => {
        let fetch = await get(`/v3/cairo/planID/${plan.memberPremiums.planID}/agebands`)
        setFetched(true)
        let standardBands_fetch = fetch.bands_v2.banded.standard, smokingBands_fetch = fetch.bands_v2.banded.smoking
        let standardBands_obj = {}, smokingBands_obj = {}, standardBands_returnValue = [], smokingBands_returnValue = []
        // deband standard into each age (standard and smoking band lengths may not match)
        for(let i = 0; i < standardBands_fetch.length; i++){
            for (let j = standardBands_fetch[i]['minimumAge']; j <= standardBands_fetch[i]['maximumAge']; j++){
                standardBands_obj[j] = standardBands_fetch[i].rate
                let tempStandardBand = {'minimumAge': j, 'maximumAge': j, rate: standardBands_fetch[i].rate}
                standardBands_returnValue.push(tempStandardBand)
            }
        }
        // deband smoking into each age
        for(let i = 0; i < smokingBands_fetch.length; i++){
            for (let j = smokingBands_fetch[i]['minimumAge']; j <= smokingBands_fetch[i]['maximumAge']; j++){
                smokingBands_obj[j] = smokingBands_fetch[i].rate
                let tempSmokingBand = {'minimumAge': j, 'maximumAge': j, rate: smokingBands_fetch[i].rate}
                smokingBands_returnValue.push(tempSmokingBand)
            }
        }
        setStandardBands(standardBands_obj)
        setStandardBands_current(standardBands_obj)
        setSmokingBands(smokingBands_obj)
        setSmokingBands_current(smokingBands_obj)
        let _returnValue = fetch
        _returnValue.bands_v2.banded.standard = standardBands_returnValue
        _returnValue.bands_v2.banded.smoking = smokingBands_returnValue
        setReturnValue(_returnValue)
        setLoading(false)
    }

    const parseVal = val => val[0] === "$" ? parseFloat(val.toString().split('').filter(char => char !== ',').slice(1).join('')) : parseFloat(val.toString().split('').filter(char => char !== ',').join(''))
    
    const updateReturnValue = (i, type, val) => {
        setChanged(true)
        let _returnValue = {...returnValue}
        _returnValue.bands_v2.banded[type][i].rate = parseVal(val)
        setReturnValue(_returnValue)
    }

    const reband = (bands) => {
        let rebanded = []
        let tempBand = {'minimumAge': 0, 'maximumAge': 0, rate: parseVal(bands[0].rate)}
        for (let i = 0; i < bands.length; i++){
            let currentBand = bands[i]
            if(tempBand.rate !== parseVal(currentBand.rate)){
                tempBand.maximumAge = currentBand.maximumAge - 1
                rebanded.push({...tempBand})
                tempBand.minimumAge = currentBand.minimumAge
                tempBand.maximumAge = currentBand.maximumAge
                tempBand.rate = parseVal(currentBand.rate)
            }
        }
        tempBand.maximumAge = 65 // set the max age for the final band to 65 and push to the return value array
        rebanded.push(tempBand)
        return rebanded
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let res = returnValue
        res.bands_v2.banded.standard = reband(res.bands_v2.banded.standard)
        res.bands_v2.banded.smoking = reband(res.bands_v2.banded.smoking)
        await put(`/v3/cairo/planID/${plan.memberPremiums.planID}/agebands`, res)
        setChanged(false)
        setLoading(true)
        getAgeBands()
    }

    const renderTable = Object.values(standardBands).map((band, i) => <AgeBandEditorRow standardBand_param={band} standardBand_current={standardBands_current[i]} smokingBand_param={smokingBands[i]} smokingBand_current={smokingBands_current[i]} i={i} updateReturnValue={updateReturnValue} /> )
    
    return (
        <div className='form-container'>
                <form onSubmit={(e) => handleSubmit(e)} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {loading ? 
                        <p>loading</p> :
                        <table className='table table-responsive table-striped' style={{ display: 'table'}}>
                            <th className='table-th' style={{padding: '10px 0px 0px 0px', borderTop: 'none'}}>
                                <div style={{position: "absolute", right: "10px"}}>
                                    {!loading && <button button className={changed ? 'btn btn-danger' : 'btn btn-light'}>update</button>}
                                    <button style={{marginLeft: '10px'}} className='btn btn-success' onClick={() => handleCloseAgeBandsForm()}>cancel</button>
                                </div>
                                <h3>AGE BAND EDITOR</h3>
                                <h5>{plan.carrier} - {plan.name}</h5>
                                <div className='table-columns'>
                                    <span className='table-column-header age-banded-input'>AGE</span>
                                    <span className='table-column-header age-banded-input'>STANDARD</span>
                                    <span className='table-column-header age-banded-input'>+/-</span>
                                    <span className='table-column-header age-banded-input'>SMOKING</span>
                                    <span className='table-column-header age-banded-input'>+/-</span>
                                </div>
                            </th>
                            { renderTable }
                        </table>
                    }
                </form>
        </div>
    )
}
