import React, { Component } from 'react';
import { get } from 'utils/api-primitives'

export default class Tally extends Component {
  state = { counts: {} }

  componentDidMount() {
    get('/cairo/tally').then(rsp => this.setState(rsp)).catch(console.error)
  }

  render = () => <table className='table table-responsive table-striped' cellSpacing='15px'>
    <thead>
      <tr>
        <th>Groups</th>
        <th>Members</th>
        <th>Selected Plans</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{this.state.counts.groups}</td>
        <td>{this.state.counts.members}</td>
        <td>{this.state.counts.selectedPlans}</td>
      </tr>
    </tbody>
  </table>
}