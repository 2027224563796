import React from 'react'
import SupplementalFormRow from './supplementalFormRow'
import './forms.css'

export default function SupplementalForm({member, groupID, updateTablePremiums}) {
    let [mem] = member

    let supplementalEditableTypes = ['criticalIllness', 'hospital']
    let editablePlans = []
    for(const key in mem){
        if(supplementalEditableTypes.includes(key)){
            editablePlans.push({key: mem[key]})
        }
    }

    const rows = editablePlans.map( plan => <SupplementalFormRow plan={plan} member={mem} groupID={groupID} updateTablePremiums={updateTablePremiums}/> )

  return (
    <div className='form-container'>
        <h4>Update Supplemental Rates for {mem.name}</h4>
            <table className='table table-responsive table-striped'>
            <tbody>
                {rows}
            </tbody>
            </table>
    </div>
  )
}