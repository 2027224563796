import React, { Component } from 'react'
import { put } from 'utils/api-primitives'
import errorAlert from 'utils/alert'
import _ from 'lodash'
import supportApi from 'utils/supportApi'

export const flattenKeys = (obj, path = []) =>
  !_.isObject(obj)
    ? [path.join('.')]
    : _.reduce(obj, (cum, next, key) => cum.concat(flattenKeys(next, path.concat([key]))), []);

class PlanEditor extends Component {

  static carriers = []

  state = {}

  async componentDidMount() {
    if (PlanEditor.carriers.length === 0) {
      try {
        PlanEditor.carriers = await supportApi.majorCarriers()
      } catch {}
    }
  }

  componentDidUpdate(prevProps) {
    throw new Error("YO OVERRIDE THIS")
  }

  headerBeforeKey(key) {
    return undefined
  }

  shouldRender() {
    return !!this.state.id
  }

  titleForKey(key) {
    switch (key) {
      case 'premium.employee.individual':
      case 'rate.individual':
        return 'ee'
      case 'premium.employee.singleParent':
      case 'rate.singleParent':
        return 'ee+child'
      case 'premium.employee.couple':
      case 'rate.couple':
        return 'ee+spouse'
      case 'premium.employee.family':
      case 'rate.family':
        return 'ee+family'
      case 'oopMax':
        return 'OoP Max'
      case 'premium.employer':
        return 'Total Rate'
      case 'copay.primaryCarePhysician':
        return 'Primary Care Visit'
      default:
        return undefined
    }
  }

  inputWidthForKey(key) {
    return undefined
  }

  inputRequiredForKey(key) {
    return undefined
  }

  rowForKey(key) {
    if (key === 'groupID' || key === 'id' || key === 'plan.id') return null;

    const size = this.inputWidthForKey(key);
    const type = this.inputTypeForKey(key);
    const required = this.inputRequiredForKey(key);

    let title = this.titleForKey(key);
    if (!title) {
      title = key.split('.');
      title = _.last(title);
      title = _.startCase(title);
    }

    const row = <tr key={key}>
      <td key='label'>{title}</td>
      <td key='input'>
        {['carrier', 'plan.carrier'].includes(key)
          ? <select className='custom-select' name={key} onChange={this.onChange} value={_.get(this.state, key)}>
            <option></option>
            {PlanEditor.carriers.map(carrier => <option value={carrier} key={carrier}>{_.startCase(carrier)}</option>)}
          </select>
          : 
          <>
            {
              type === 'checkbox' ? 
                <input name={key} value={_.get(this.state, key)} onChange={(this.onChangeCheckbox)} type={type} size={size} required={required} checked={_.get(this.state, key)}/> :
                <input className='form-control' name={key} value={_.get(this.state, key)} onChange={this.onChange} type={type} size={size} required={required}/>
            }
          </>
        }
      </td>
    </tr>

    const header = this.headerBeforeKey(key);
    if (header) {
      return <>
        <tr><th colSpan={2} style={{ textAlign: 'left' }}>{header}</th></tr>
        {row}
      </>
    } else {
      return row
    }
  }

  onChange = (event) => {
    const path = event.target.name
    const value = event.target.value
    this.setState(state => _.set(state, path, value));
  }

  onChangeCheckbox = (event) => {
    const key = event.target.name
    const value = _.get(this.state, key)
    this.setState(state => _.set(state, key, !value));
  }

  inputTypeForKey(key) {
    return undefined;
  }

  render() {
    if (!this.shouldRender()) return <></>;

    const rows = flattenKeys(this.state).map(this.rowForKey.bind(this));

    return <>
      <h1>TITLES ARE OUT OF ORDER SORRY!</h1>
      <form onSubmit={this.onSubmit.bind(this)}>
        <table className='table table-responsive table-striped'>
          <tbody>
            {rows}
          </tbody>
        </table>
        <input className='btn btn-primary' type='submit' />
      </form>
    </>
  }

  api(obj) {
    if(obj.type === 'accident' || obj.type === 'cancer'){
      return put(`/v3/cairo/groups/${this.props.groupID}/plans`, obj)
    } else {
      return put(`/cairo/groups/${this.props.groupID}/plans`, obj)
    }
  }

  pharaohRequestObject() {
    return this.state
  }

  onSubmit = (event) => {
    event.preventDefault();
    delete this.pharaohRequestObject().errors
    this.api(this.pharaohRequestObject())
      .then(() => window.location.reload())
      .catch(errorAlert);
  }
}

class MedicalPlanEditor extends PlanEditor {

  state = { type: 'PPO' }

  componentDidUpdate(prevProps) {
    if (this.props.id === prevProps.id) return;
    this.setState(_.cloneDeep(this.props));
  }

  titleForKey(key) {
    switch (key) {
      case 'isLevelFunded':
        return 'Level Funded'
      case 'sbc':
        return 'SBC'
      case 'oopMax':
        return 'Out‐of‐Pocket Maximum'
      case 'oonCoverage':
        return "Out‐of‐Network is covered"
      default:
        return super.titleForKey(key);
    }
  }

  inputTypeForKey(key) {
    switch (key) {
      case "isLevelFunded":
      case "oonCoverage":
      case "hsaEligible":
        return "checkbox"
      default:
        return super.inputTypeForKey(key);
    }
  }

  inputWidthForKey(key) {
    switch (key) {
      case 'name':
      case 'sbc':
        return 100
      default:
        return super.inputWidthForKey(key)
    }
  }

  rowForKey(key) {
    if (key === 'type') {
      return <tr key='type'>
        <td key='label'>Type</td>
        <td key='input'>
          <select className="custom-select" name="type" value={this.state.type} onChange={this.onChange}>
            {/* sorted by popularity (carrier popularity) */}
            <option key="PPO" value="PPO" selected>PPO</option>
            <option key="HMO" value="HMO">HMO</option>
            <option key="POS" value="POS">POS</option>
            <option key="EPO" value="EPO">EPO</option>
            <option key="Indemnity" value="Indemnity">Indemnity</option>
            <option key="FixedBenefitNoNetwork" value="FixedBenefitNoNetwork">Fixed Benefit - No Network</option>
            <option key="RBP" value="Reference-Based Pricing">Reference-Based Pricing</option>
          </select>
        </td>
      </tr>
    } else {
      return super.rowForKey(key)
    }
  }

  onChange = (event) => {
    const path = event.target.name
    const value = event.target.value
    this.setState(state => _.set(state, path, value));
  }

  pharaohRequestObject() {
    const premiums = this.state.premium.employee
    const source = 'heather';  // heather dept.

    let plan = _.cloneDeep(this.state);
    plan['fundingType'] = plan.isLevelFunded ? 'Medically Underwritten' : 'Fully Insured';
    plan['source'] = source;
    plan.oonCoverage = plan.oonCoverage === 'true'
    plan.hsaEligible = plan.hsaEligible === 'true'

    delete plan.isLevelFunded;
    delete plan.premium;

    const rate = {
      individual: premiums.individual,
      singleParent: premiums.singleParent,
      couple: premiums.couple,
      family: premiums.family,
      source: source
    };

    return { plan, rate, type: 'medical' }
  }
}

class AncillaryPlanEditor extends PlanEditor {
  componentDidUpdate(prevProps) {
    if (_.get(prevProps, 'plan.id') === _.get(this.props, 'plan.id')) return;
    this.setState({ plan: this.props.plan, rates: this.props.rates }); // we want this order
  }

  rowForKey(key) {
    if (key === 'plan.id' || key === 'rates.id') {
      return null
    } else {
      return super.rowForKey(key)
    }
  }

  headerBeforeKey(key) {
    if (key === 'rates.singleParent') {
      return "Premiums"
    } else {
      return super.headerBeforeKey(key)
    }
  }

  shouldRender() {
    return !!this.state.plan
  }
}

class DentalPlanEditor extends AncillaryPlanEditor {

  componentDidUpdate(prevProps) {
    if (_.get(prevProps, 'plan.id') === _.get(this.props, 'plan.id')) return;
    const { data: d } = this.props?.plan

    const plan = {
      id: this.props.plan.id,
      carrier: this.props.plan.carrier,
      name: this.props.plan.name,
      data: {
        version: '2',
        preventativeCoinsuranceInNetwork: d?.preventativeCoinsuranceInNetwork || d?.dentalPreventativeInNetwork || '',
        preventativeCoinsuranceOutOfNetwork: d?.preventativeCoinsuranceOutOfNetwork || d?.dentalPreventativeOutOfNetwork || '',
        preventativeDeductibleInNetwork: d?.preventativeDeductibleInNetwork || '',
        preventativeDeductibleOutOfNetwork: d?.preventativeDeductibleOutOfNetwork || '',
        preventativeMaxInNetwork: d?.preventativeMaxInNetwork || '',
        preventativeMaxOutOfNetwork: d?.preventativeMaxOutOfNetwork || '',
        basicCoinsuranceInNetwork: d?.basicCoinsuranceInNetwork || d?.dentalBasicInNetwork || '',
        basicCoinsuranceOutOfNetwork: d?.basicCoinsuranceOutOfNetwork || d?.dentalBasicOutOfNetwork || '',
        basicDeductibleInNetwork: d?.basicDeductibleInNetwork || d?.individualInNetworkDeductible || '',
        basicDeductibleOutOfNetwork: d?.basicDeductibleOutOfNetwork || d?.individualOutOfNetworkDeductible || '',
        basicMaxInNetwork: d?.basicMaxInNetwork || '',
        basicMaxOutOfNetwork: d?.basicMaxOutOfNetwork || '',
        majorCoinsuranceInNetwork: d?.majorCoinsuranceInNetwork || d?.dentalMajorInNetwork || '',
        majorCoinsuranceOutOfNetwork: d?.majorCoinsuranceOutOfNetwork || d?.dentalMajorOutOfNetwork || '',
        majorDeductibleInNetwork: d?.majorDeductibleInNetwork || d?.individualInNetworkDeductible || '',
        majorDeductibleOutOfNetwork: d?.majorDeductibleOutOfNetwork || d?.individualOutOfNetworkDeductible || '',
        majorMaxInNetwork: d?.majorMaxInNetwork || '',
        majorMaxOutOfNetwork: d?.majorMaxOutOfNetwork || '',
        familyDeductibleMaximum: d?.familyDeductibleMaximum || '',
        deductibleCombined: d?.deductibleCombined || true,
        maxCombined: d?.maxCombined || true
      }
    }

    this.setState({ plan, rates: this.props.rates })
  }

  pharaohRequestObject() {
    const rate = { ...this.state.rates, source: 'heather' }
    const plan = { ...this.state.plan }
    plan.data.deductibleCombined = plan?.data?.deductibleCombined ? 'true' : 'false'
    plan.data.maxCombined = plan?.data?.maxCombined ? 'true' : 'false'
    return {
      plan: { ...plan, type: 'dental' },
      rate,
      type: 'dental'
    }
  }

  inputTypeForKey(key) {
    switch (key) {
      case "plan.data.deductibleCombined":
      case "plan.data.maxCombined":
        return "checkbox"
      default:
        return super.inputTypeForKey(key);
    }
  }

  rowForKey(key) {
    if (key === 'plan.type' || key === 'plan.data.version') return 
    return super.rowForKey(key)
  }

  inputRequiredForKey(key) {
    switch (key) {
      case "plan.carrier":
      case "plan.name":
      case "plan.data.preventativeCoinsuranceInNetwork":
      case "plan.data.preventativeDeductibleInNetwork":
      case "plan.data.preventativeMaxInNetwork":
      case "plan.data.basicCoinsuranceInNetwork":
      case "plan.data.basicDeductibleInNetwork":
      case "plan.data.basicMaxInNetwork":
      case "plan.data.majorCoinsuranceInNetwork":
      case "plan.data.majorDeductibleInNetwork":
      case "plan.data.majorMaxInNetwork":
        return true
      default:
        return super.inputRequiredForKey(key);
    }
  }
}

class VisionPlanEditor extends AncillaryPlanEditor {
  pharaohRequestObject() {
    const rate = { ...this.state.rates, couple: this.state.rates.couple, source: 'heather' }
    return {
      plan: { ...this.state.plan, type: 'vision' },
      rate,
      type: 'vision'
    }
  }
}

class LifePlanEditor extends AncillaryPlanEditor {
  pharaohRequestObject() {
    const rate = { ...this.state.rates, couple: this.state.rates.couple, source: 'heather' }
    return {
      plan: { ...this.state.plan, type: 'life' },
      rate,
      type: 'life'
    }
  }
}

class SupplementalPlanEditor extends AncillaryPlanEditor {
  pharaohRequestObject() {
    const type = this.state.plan.type
    const rate = { ...this.state.rates, couple: this.state.rates.couple, source: 'heather' }
    return {
      plan: { ...this.state.plan, type: type },
      rate,
      type
    }
  }
}

export { MedicalPlanEditor, DentalPlanEditor, VisionPlanEditor, LifePlanEditor, SupplementalPlanEditor }
