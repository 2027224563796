import React, { useState } from 'react'
import { get } from 'utils/api-primitives'
import errorAlert from 'utils/alert'
import TableRender from 'utils/TableRender'
import { startCase } from 'lodash'

const ProducerDump: React.FC = () => {
  const [producers, setProducers] = useState<any[]>()

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const searchQuery = event.currentTarget['search']?.value
    get(`/v3/cairo/producers?query=${searchQuery || ''}`).then((rsp: any[]) => setProducers(rsp)).catch(errorAlert)
  }

  function mangle(producer: any) {
    return {
      'Name': producer.name || "–",
      'Email': <a href={`mailto:${producer.email}`}>{producer.email}</a> || "–",
      'Business Name': producer.businessName || "–",
      'Unique Shop': producer.slug || "–",
      'NIPR': producer.nipr || "–",
      'Licenses': producer.licenses?.length > 0 ? <pre>{producer.licenses?.map((li: any) => Object.keys(li).map(k => `${startCase(k)}: ${li[k]}`).join('\n')).join('\n\n')}</pre> : "–",
      'Appointments': producer.appointments?.length > 0 ? <pre>{producer.appointments?.map((ap: string) => `${ap}\n`)}</pre> : "–",
      'Agency ID': producer.agencyId || "–",
      'Producer ID': producer.producerId || "–",
      'Unique Shop URL': producer.shopURL ? <a href='producer.shopURL' target='_blank'>{producer.shopURL}</a> : "-",
      'Whitelabel': producer.whitelabel || "-"
    }
  }

  return <form onSubmit={onSubmit}>
    <input className='form-control' type='text' placeholder='Producer name or email' name='search' />
    <input className='btn btn-primary' type='submit' value='Search' />
    {producers && producers.length > 0
      ? <TableRender rows={[Object.keys(mangle(producers[0])), ...producers.map((pr: any) => Object.values(mangle(pr)))]} />
      : producers && <p>No producers found</p>
    }
  </form>
}

export default ProducerDump