import React, { Component } from 'react'
import api from 'utils/api'
import errorAlert from 'utils/alert'

export class MedicalUnderwritingStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      medical_underwriting_complete: this.props.medical_underwriting_complete
    }
  }

  handler = (complete, groupId, memberEmail) => {
    const payload = {
      "email": memberEmail,
      "medical_underwriting_complete": complete
    }
    api.updateMedicalUnderwritingStatus(groupId, payload).then(() => {
      // Display the update for support team's sake
      this.setState({
        medical_underwriting_complete: complete
      })
    }).catch(errorAlert)
  }

  render = () => <>
    <div>
      {this.state.medical_underwriting_complete ? "Complete" : "Incomplete"}
    </div>
    <div>
      <button className="btn btn-success" onClick={() => this.handler(true, this.props.groupId, this.props.memberEmail)}>Mark “Complete”</button>
    </div>
    <div>
      <button className="btn btn-warning" onClick={() => this.handler(false, this.props.groupId, this.props.memberEmail)}>Mark “Incomplete”</button>
    </div>
  </>
}

export default MedicalUnderwritingStatus
