import React, { Component } from 'react'
import api from 'utils/api'
import sApi from 'utils/supportApi'
import errorAlert from 'utils/alert'
import queryString from 'query-string'
import GroupDumpMembers from './GroupDump/GroupDump.Members'
import GroupDumpArchive from './GroupDump/GroupDump.Archive'
import GroupDumpSelf from './GroupDump/GroupDump.Self'
import GroupDumpRemind from './GroupDump/GroupDump.Remind'
import GroupDumpMedicalPlans from './GroupDump/GroupDump.MedicalPlans'
import { GroupDumpDentalPlans, GroupDumpVisionPlans, GroupDumpLifePlans, GroupDumpSupplementalPlans } from './GroupDump/GroupDump.AncillaryPlans'
import GroupDumpSplits from './GroupDump/GroupDump.Splits'
import { get } from 'utils/api-primitives'
import GroupDumpApplication from './GroupDump/GroupDump.Application'
import GroupDumpAuditor from './GroupDump/GroupDumpAuditor'

class GroupDump extends Component {
  state = { id: '', ancillary: '' }

  componentDidMount() {
    const locationParams = queryString.parse(window.location.search)
    if (locationParams.id) {
      this.setState({ id: locationParams.id }, this.go)
    }
  }

  go = (event = null) => {
    if (event) event.preventDefault()

    this.setState({ loading: true })

    const p1 = api.getGroup(this.state.id);
    const p2 = get(`/v3/cairo/groups/${this.state.id}/plans`);
    const p3 = sApi.supportGroups(this.state.id);
    const p4 = api.getContributionSplit(this.state.id);
    const p5 = get(`/v3/groups/${this.state.id}/carrierApplication`).catch(() => {});
    const p6 = api.getSelectedSupplementalPlans(this.state.id);

    Promise.all([p1, p2, p3, p4, p5, p6]).then(values => {
      let [group, plans, members, splits, application, supplemental] = values;
      const currentPlans = plans.filter(p => p.deleted_at === undefined)
      const deletedPlans = plans.filter(p => p.deleted_at !== undefined)

      const dentalPlans = supplemental.filter(p => p.plan.type === "dental" )
      const visionPlans = supplemental.filter(p => p.plan.type === "vision" )
      const lifePlans = supplemental.filter(p => ["life", "lifeEE", "lifeER"].includes(p.plan.type) )
      const filteredSupplemental = supplemental.filter(p => ["accident", "cancer", "criticalIllness", "hospital", "std", "ltdEE", "ltdER", "stdEE", "stdER"].includes(p.plan.type) )
      
      const dentalArray = []
      const visionArray = []
      const lifeArray = []

      dentalPlans.forEach(p => {
        p.rates = p.rate
        delete p.rate
        dentalArray.push(p)
      })
      visionPlans.forEach(p => {
        p.rates = p.rate
        delete p.rate
        visionArray.push(p)
      })
      lifePlans.forEach(p => {
        p.rates = p.rate
        delete p.rate
        lifeArray.push(p)
      })

      const supplementalObj = {}
      filteredSupplemental.forEach(p => {
        p.rates = p.rate
        delete p.rate
        supplementalObj[p.plan.type] = [p]
      })

      this.setState({
        group,
        plans: { currentPlans: currentPlans, deletedPlans: deletedPlans },
        members,
        ancillary: { dental: dentalArray, vision: visionArray, life: lifeArray },
        splits,
        application,
        supplemental: supplementalObj
      }, () => {
        const location = { ...queryString.parse(window.location.search), id: this.state.id };
        window.history.pushState(location, '', '?' + queryString.stringify(location))
      })
    }).catch(errorAlert).finally(() =>
      this.setState({ loading: false })
    )
  }

  handler = (event) =>
    this.setState({ id: event.target.value })

  render = () => <>
    <form onSubmit={this.go}>
      <input className='form-control' type='text' value={this.state.id} onChange={this.handler} placeholder='Group ID' />
      <input className='btn btn-primary' type='submit' />
    </form>

    {this.state.loading && <p>Loading…</p>}

    <Group {...this.state} planEditCallback={this.editPlanWithID} />
    <GroupDumpRemind />
    <GroupDumpArchive groupID={this.state.group && this.state.group.id} />
  </>
}

function toggleApproval(event, groupID) {
  event.preventDefault()
  api.toggleApproval(groupID).then(() => { window.location.reload() })
}

function toggleHBAApproval(event, groupID) {
  event.preventDefault()
  api.toggleHBAApproval(groupID).then(() => { window.location.reload() })
}

function Group(props) {
  if (!props.group) return <></>
  const hasHBA = props.plans.currentPlans.some(p => p.carrier === 'Health Benefit Alliance')

  return <>
    <h3>
      {props.group.name}<br/>
      <button className={`btn btn-${props.group.approved ? 'danger' : 'warning'}`} onClick={e => toggleApproval(e, props.group.id)}>{props.group.approved ? 'Revoke shopping approval' : 'Approve to shop'}</button>
      { hasHBA && <>
        <br/>
        <button
          className={`btn btn-${props.group.hbaApproved ? 'danger' : 'warning'}`}
          onClick={e => toggleHBAApproval(e, props.group.id)}
          >
            {props.group.hbaApproved ? 'Revoke HBA approval' : 'Add HBA Approval'}
        </button>
      </>}
    </h3>
    <GroupDumpSelf {...props} />
    <GroupDumpAuditor members={props.members} group={props.group}/>
    <h3>Medical Plans</h3>
    <GroupDumpMedicalPlans isEnrolled={props.group.group.pipelineStatus === 'enrolled'} plans={props.plans} groupID={props.group.id} />
    <h3>Dental Plans</h3>
    <GroupDumpDentalPlans plans={props.ancillary.dental} groupID={props.group.id} />
    <h3>Vision Plans</h3>
    <GroupDumpVisionPlans plans={props.ancillary.vision} groupID={props.group.id} />
    <h3>Life Plans</h3>
    <GroupDumpLifePlans plans={props.ancillary.life} groupID={props.group.id} />
    <h3>Supplemental Plans</h3>
    { Object.values(props.supplemental).map(plan => <GroupDumpSupplementalPlans plans={plan} groupID={props.group.id} /> ) }
    <div></div>
    <h3>Contribution Splits</h3>
    <GroupDumpSplits splits={props.splits} />
    <h3>Group Application</h3>
    <GroupDumpApplication group={props.group} application={props.application} />
    <h3>Members</h3>
    <GroupDumpMembers members={props.members} group={props.group} plans={props.plans.currentPlans} ancillary={props.ancillary} splits={props.splits}/>
    <br />
    <hr />
  </>
}

export default GroupDump
