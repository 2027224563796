import React, { Component } from 'react';
import { post } from 'utils/api-primitives';
import errorAlert from '../utils/alert'
import queryString from 'query-string'
import moment from 'moment'

class GroupSearch extends Component {
  state = {
    groups: null
  }

  constructor(props) {
    super(props)
    this.state = { query: '' }
  }

  componentDidMount() {
    const locationParams = queryString.parse(window.location.search)
    if (locationParams.query) {
      this.setState({ query: locationParams.query }, this.go)
    }
  }

  render() {
    const form = <form key={0} onSubmit={this.go}>
      <input className='form-control' type='text' value={this.state.query} onChange={this.handler} placeholder='Name' />
      <input className='btn btn-primary' type='submit' value='Search' />
    </form>

    if (this.state.loading) {
      return [form, <p key={1}>Loading…</p>]
    }

    const { groups } = this.state;

    if (groups) {
      if (groups.length) {
        return [form].concat(groups.map((group) => <>
          <table className='table table-responsive table-striped' key={group.id}>
            <tr><th>Name</th><th>{group.name} ({moment.utc(group.effectiveDate).format('YYYY')})</th></tr>
            <tr><th>Address</th><td>{group.address1}<br />{group.address2}<br />{group.state}, {group.zip_code}</td></tr>
            <tr><th>ID</th><td><a href={`?id=${group.id}`}>{group.id}</a></td></tr>
            <tr><th>Phone</th><td>{group.phoneNumber}</td></tr>
            <tr><th>Created At</th><td>{moment(group.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td></tr>
            {group.created_at !== group.updated_at &&
              <tr><td>Updated At</td><td>{moment(group.updated_at).format('MMMM Do YYYY, h:mm:ss a')}</td></tr>
            }
            {group.group_managers && group.group_managers.length > 0 &&
              <tr><td>Group Managers</td><td><pre>{group.group_managers.join("\r\n")}</pre></td></tr>
            }
          </table>
          <hr/></>));
      } else {
        return [form, <p key={1}>No results</p>]
      }
    } else {
      return form
    }
  }

  handler = (event) =>
    this.setState({ query: event.target.value })

  go = (event = null) => {
    if (event) event.preventDefault();

    this.setState({ loading: true });

    post('/v2/support/search/groups', { query: this.state.query }).then(groups => {
      this.setState({ groups }, () => {
        let location = { ...queryString.parse(window.location.search), query: this.state.query };
        window.history.pushState(location, "", "?" + queryString.stringify(location))
      })
    }).catch(errorAlert).finally(() =>
      this.setState({ loading: false })
    );
  }
}

export default GroupSearch;
