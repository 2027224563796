import React from 'react'
import { MedicalPlanEditor, DentalPlanEditor, VisionPlanEditor, LifePlanEditor, flattenKeys } from './PlanEditor.js'
import { post } from 'utils/api-primitives'
import UploadSBC from 'components/UploadSBC'
import errorAlert from 'utils/alert'
import styles from './PlanCreator.module.css'
import numeral from 'numeral'
import _ from 'lodash'

//NOTE rushed so weird inheritance bullshit

class MedicalPlanCreator extends MedicalPlanEditor {

  state = {
    "form": {
      "carrier": "",
    "name": "",
    "deductible": "",
    "oopMax": "",
    "coinsurance": "",
    "copay": {
      "primaryCarePhysician": "",
      "specialist": "",
      "urgentCare": ""
    },
    "prescription": {
      "generic": "",
      "specialty": "",
      "preferredBrand": ""
    },
    "isLevelFunded": false,
    "type": "PPO",
    "premium": {
      "employee": {
        "individual": "",
        "singleParent": "",
        "family": "",
        "couple": ""
      }
    },
    "hsaEligible": false,
    },
    
    "showMedSBCUpload": false,
    "createdPlanID": null,
    "fieldErrorsArray": [],
    "showFieldErrors": false

  }

  shouldRender() {
    return this.props.shouldRender
  }

  api(obj) {
    return post(`/cairo/groups/${this.props.groupID}/plans`, obj)
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (this.pharaohRequestObject().errors.length > 0) {
      this.setState({fieldErrorsArray: this.pharaohRequestObject().errors, showFieldErrors: true})
    } else {
      this.api(this.pharaohRequestObject())
        .then((e) => {
          this.setState({showMedSBCUplaod: true, createdPlanID: e})
        })
        .catch(errorAlert);
    }
  }

  onChange = (event) => {
    const path = event.target.name
    const value = event.target.value
    super.setState(state => _.set(state, path, value));
    this.setState({fieldErrorsArray: this.state.fieldErrorsArray.filter(e=> e !== event.target.name.split('.').pop())})
  }

  rowForKey(key) {
    if (key === 'type') {
      return <tr key='type'>
        <td key='label'>Type</td>
        <td key='input'>
          <select className="custom-select" name="type" value={this.state.type} onChange={this.onChange} defaultValue="PPO">
            {/* sorted by popularity (carrier popularity) */}
            <option key="PPO" value="PPO">PPO</option>
            <option key="HMO" value="HMO">HMO</option>
            <option key="POS" value="POS">POS</option>
            <option key="EPO" value="EPO">EPO</option>
            <option key="Indemnity" value="Indemnity">Indemnity</option>
            <option key="FixedBenefitNoNetwork" value="FixedBenefitNoNetwork">Fixed Benefit - No Network</option>
            <option key="RBP" value="Reference-Based Pricing">Reference-Based Pricing</option>
          </select>
        </td>
      </tr>
    } else {
      return super.rowForKey(key)
    }
  }

  clearID = () => {
    this.setState({showMedSBCUplaod: false, createdPlanID: null})
  }

  pharaohRequestObject() {
    const fieldErrors = []
    const premiums = this.state.premium.employee
    const source = 'heather';  // heather dept.

    let plan = _.cloneDeep(this.state);
    for (const [key, value] of Object.entries(plan.copay)) {
      if (typeof numeral(value).value() !== 'number') fieldErrors.push(key)
      plan.copay[key] = numeral(value).format('$0,0.00')
    }
    for (const [key, value] of Object.entries(plan.prescription)) {
      if (typeof numeral(value).value() !== 'number') fieldErrors.push(key)
      if (key === 'specialty') {
        plan.prescription[key] = numeral(value.slice(-1) === '%' ? numeral(value).value() : `0.${numeral(value).value()}`).format('0%')
      } else {
        plan.prescription[key] = numeral(value).format('$0,0.00')
      }
    }

    if (typeof numeral(plan.deductible).value() !== 'number') fieldErrors.push('deductible')
    plan.deductible = numeral(plan.deductible).format('$0,0.00')

    if (typeof numeral(plan.oopMax).value() !== 'number') fieldErrors.push('oopMax')
    plan.oopMax = numeral(plan.oopMax).format('$0,0.00')

    plan['fundingType'] = plan.isLevelFunded ? 'Medically Underwritten' : 'Fully Insured';
    plan['source'] = source;
    plan.oonCoverage = plan.oonCoverage === 'true'
    plan.hsaEligible = plan.hsaEligible === 'true'

    if (typeof numeral(plan.coinsurance).value() !== 'number' || numeral(plan.coinsurance).value() > 100 || numeral(plan.coinsurance).value() < 0) fieldErrors.push('coinsurance')

    if (plan.coinsurance.slice(-1) === '%') plan.coinsurance = plan.coinsurance.substring(0, plan.coinsurance.length-1);
    plan.coinsurance = numeral(`0.${numeral(plan.coinsurance).value()}`).format('0%')
    if (!plan.type) plan.type = 'PPO'

    delete plan.isLevelFunded;
    delete plan.premium;
    delete plan.form;
    delete plan.createdPlanID;

    const rate = {
      individual: premiums.individual,
      singleParent: premiums.singleParent,
      couple: premiums.couple,
      family: premiums.family, 
      source: source
    };

    for (const [key, value] of Object.entries(rate)) {
      if (key !== 'source') {
        if (typeof numeral(value).value() !== 'number') fieldErrors.push(key)
      }
    }

    return { plan, rate, type: 'medical', errors: fieldErrors }
  }

  render() {
    if (!this.shouldRender()) return <></>
    const rows = flattenKeys(this.state.form).map(this.rowForKey.bind(this));
    const foo = <>
    <form onSubmit={this.onSubmit.bind(this)}>
      <table className='table table-responsive table-striped'>
        <tbody>
          {rows}
        </tbody>
      </table>
      <input className='btn btn-primary' type='submit' />
    </form>
  </>
    return <>
      <p style={{ color: 'red' }}>Hey you! If you need to add a plan from the system, use Stargate!</p>
      {foo}
      {(this.state.showFieldErrors && this.state.fieldErrorsArray.length > 0) && <div className={styles.fieldErrors}>You have errors in the following fields: {this.state.fieldErrorsArray.map((e)=> <span className={`badge bg-warning ${styles.errorName}`} key={e}>{e}</span>)}</div>}
      {this.state.showMedSBCUplaod && 
      <div className={styles.flexContainer}>
        <div>
          <p>Would you like to upload an SBC?</p>
          { this.state.createdPlanID &&
            <UploadSBC planID={this.state.createdPlanID} type='medical' flagFunc={this.clearID} reload/>
          }
        </div>
        <div>
          <p>Or not?</p>
          <button type="button" className="btn btn-danger" onClick={() => {
            this.clearID()
            window.location.reload()
            }}>Don't Upload SBC</button>
        </div>
      </div>
    }
    </>
  }
}

class DentalPlanCreator extends DentalPlanEditor {
  state = {
    "rates": {
      "family": "",
      "singleParent": "",
      "couple": "",
      "individual": ""
    },
    "plan": {
      "name": "",
      "carrier": "",
      data: {
        version: "2",
        preventativeCoinsuranceInNetwork: "",
        preventativeCoinsuranceOutOfNetwork: "",
        preventativeDeductibleInNetwork: "",
        preventativeDeductibleOutOfNetwork: "",
        preventativeMaxInNetwork: "",
        preventativeMaxOutOfNetwork: "",
        basicCoinsuranceInNetwork: "",
        basicCoinsuranceOutOfNetwork: "",
        basicDeductibleInNetwork: "",
        basicDeductibleOutOfNetwork: "",
        basicMaxInNetwork: "",
        basicMaxOutOfNetwork: "",
        majorCoinsuranceInNetwork: "",
        majorCoinsuranceOutOfNetwork: "",
        majorDeductibleInNetwork: "",
        majorDeductibleOutOfNetwork: "",
        majorMaxInNetwork: "",
        majorMaxOutOfNetwork: "",
        familyDeductibleMaximum: "",
        deductibleCombined: true,
        maxCombined: true
      }
    }
  }

  componentDidUpdate(prevProps) { }

  shouldRender() {
    return this.props.shouldRender
  }

  api(obj) {
    return post(`/cairo/groups/${this.props.groupID}/plans`, obj)
  }
}

class VisionPlanCreator extends VisionPlanEditor {

  state = {
    "plan": {
      "name": "",
      "carrier": "",
      "data": {
        "comprehensiveVisionExamInNetwork": "",
        "contactLensesInNetwork": "",
        "contactLensesFrequencyInMonths": "",
        "framesAllowanceInNetwork": "",
        "framesAllowanceOutOfNetwork": "",
        "materialsLensesFramesAndContactsInNetwork": "",
        "standardCorrectiveLensesInNetwork": "",
        "comprehensiveVisionExamFrequencyInMonths": "",
        "comprehensiveVisionExamOutOfNetwork": "",
        "framesAllowanceFrequencyInMonths": "",
        "standardCorrectiveLensesFrequencyInMonths": ""
      },
    },
    "rates": {
      "individual": "",
      "singleParent": "",
      "family": "",
      "couple": ""
    }
  }

  componentDidUpdate(prevProps) { }

  shouldRender() {
    return this.props.shouldRender
  }

  api(obj) {
    obj.fundingType = obj.isLevelFunded ? 'Medically Underwritten' : 'Fully Insured'
    return post(`/cairo/groups/${this.props.groupID}/plans`, obj)
  }
}

class LifePlanCreator extends LifePlanEditor {

  state = {
    "plan": {
      "name": "",
      "carrier": "",
      "data": {
        "benefitsSpouse": "",
        "benefitsPerChild": "",
        "benefitsIndividual": "",
        "disabilityProvision": "",
        "supplementalAD&DIncluded": "",
        "willPreparationAndEstateResolutionServices": ""
      },
    },
    "rates": {
      "individual": "",
      "singleParent": "",
      "family": "",
      "couple": ""
    }
  }

  componentDidUpdate(prevProps) { }

  shouldRender() {
    return this.props.shouldRender
  }

  api(obj) {
    return post(`/cairo/groups/${this.props.groupID}/plans`, obj)
  }
}

export { MedicalPlanCreator, DentalPlanCreator, VisionPlanCreator, LifePlanCreator }
