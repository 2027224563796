import React, { useEffect, useState } from 'react';
import errorAlert from '../utils/alert'
import TableRender from '../utils/TableRender'
import { startCase } from 'lodash'
import moment from 'moment'
import { get, post } from 'utils/api-primitives';
import { planRows } from './GroupDump/GroupDump.MedicalPlans';

const InternalQuote: React.FC = () => {
    const [groupSize, setGroupSize] = useState<number>(1)
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const [industries, setIndustries] = useState<{ sic_code: string, title: string }[]>([])

    useEffect(() => {
        async function industries() {
            try {
                setIndustries(await get('/industries'))
            } catch (error) {
                errorAlert(error)
            }
        }
        industries()
    }, [])

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        setLoading(true)
        post('/v3/cairo/quote/quick', {
            groupSize: parseInt(data.get('groupSize') as string),
            zipCode: data.get('zipCode'),
            averageAge: parseInt(data.get('averageAge') as string),
            effectiveDate: data.get('effectiveDate'),
            sicCode: parseInt(data.get('sicCode') as string),
            tier: data.get('tier'),
        }).then(rsp => {
            setPlans(rsp.plans)
        }).catch(errorAlert)
        .finally(() => {
            setLoading(false)
        })
    }

    return <>
        <form onSubmit={onSubmit}>
            <label>
                ZIP Code: 
                <input className='form-control' name='zipCode' type='text' placeholder='ZIP Code' required />
            </label>
            <label>
                Group Size: 
                <input className='form-control' name='groupSize' type='number' placeholder='Group Size' value={groupSize} min={1} onChange={e => setGroupSize(parseInt(e.currentTarget.value))} required />
            </label>
            <label>
                Average Age: 
                <input className='form-control' name='averageAge' type='text' placeholder='Average Age' required />
            </label>
            <label>
                SIC Code: 
                <select className='custom-select' name='sicCode' placeholder='SIC Code' required>
                    {industries.map(({ sic_code, title }, idx) => <option key={idx} value={sic_code}>{`${sic_code}: ${title}`}</option>)}
                </select>
            </label>
            <label>
                Effective Date: 
                <select className='custom-select' name='effectiveDate' placeholder='Effective Date' required>
                    {Array(12).fill(0).map((_, idx) => {
                        const date = moment.utc().date(1).add(idx + 1, 'month').hours(0).minutes(0).seconds(0).milliseconds(0)
                        return <option value={date.format()} key={idx}>{date.format('YYYY-MM-DD').toString()}</option>
                    })}
                </select>
            </label>
            <label>
                Tier (Optional): 
                <select className='custom-select' name='tier' placeholder='Tier (Optional)' disabled={groupSize !== 1}>
                    {['individual', 'couple', 'singleParent', 'family'].map(tier => {
                        return <option value={tier} key={tier}>{startCase(tier)}</option>
                    })}
                </select>
            </label>
            <br />
            <input className='btn btn-primary' type='submit' value='Submit' disabled={loading} />
        </form>
        {loading ? 'Loading...' : plans.length > 0 &&
            <TableRender rows={planRows(plans)} />
        }
    </>
}

export default InternalQuote