import React from 'react'
import TableRender from '../../utils/TableRender'

interface Props {
    group: any
    application: any
}

const GroupDumpApplication: React.FC<Props> = ({ application }) => {
    if (!application) return <>—</>

    const rows = () => {
      const app = mangleApplication(application);
      return [Object.keys(app), Object.values(app)];
    }
  
    return <TableRender rows={rows()} />
}

function mangleApplication(application: any) {
  return {
    "Business Type": application.business_type || "–",
    "Business Age": application.business_age || "–",
    "Billing Name": application.billing_name || "–",
    "Business Phone": application.business_phone || "–",
    "Business Email": application.business_email || "–",
    "HR Name": application.hr_name || "–",
    "HR Phone": application.hr_phone || "–",
    "HR Email": application.hr_email || "–",
    "Waiting Period": application.waiting_period || "–",
    "Employed 20": application.employed_20?.toString() || "–",
    "COBRA Select": application.cobra_select?.toString() || "–",
    "Offer Optional COBRA": application.offer_optional_cobra?.toString() || "–",
    "Other Business Organizations": application.other_business_organizations?.toString() || "–",
    "Multiple Locations": application.multiple_locations?.toString() || "–",
    "Other Business Addresses": application.other_business_addresses || "–",
    "Offering Another Group Medical": application.offering_another_group_medical?.toString() || "–",
    "Full-time Employees": application.full_time_percent || "–",
    "Part-time Employees": application.part_time_percent || "–",
    "ID": application.id || "–"
  }
}

export default GroupDumpApplication