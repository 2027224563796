import React from "react";
import FormCreator from './Forms/FormCreator'

const App: React.FC = () => {
  return <details>
    <summary style={{ cursor: 'pointer' }}>Form creator</summary>
    <h3>Form Creator</h3>
    <FormCreator />
  </details>
};

export default App;
