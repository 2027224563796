import { formatDate } from 'utils/formatters'
import { utcMidnightToLocalMidnight } from 'utils/betterDateFormatters'

export const parseUTC = date => formatDate(utcMidnightToLocalMidnight(date))

export const parseAge = (date) => {
    if (date === undefined) return -1
    let dob = parseUTC(date), dobYear = parseInt(dob.slice(0, 4)), dobMonth = parseInt(dob.slice(5, 7)), dobDay = parseInt(dob.slice(8, 10))
    let today = parseUTC(Date.now()), todayYear = parseInt(today.slice(0, 4)), todayMonth = parseInt(today.slice(5, 7)), todayDay = parseInt(today.slice(8, 10))
    let age = todayYear - dobYear
    if(dobMonth > todayMonth){
        age--
    } else if(dobMonth === todayMonth){
        if(dobDay > todayDay) age--
    }
    return age
}

export const parseAgeAtEffectiveDate = (date, effDate) => {
    if (date === undefined) return -1
    if (effDate === undefined) return -2
    let dob = parseUTC(date), dobYear = parseInt(dob.slice(0, 4)), dobMonth = parseInt(dob.slice(5, 7)), dobDay = parseInt(dob.slice(8, 10))
    let ed = parseUTC(effDate), edYear = parseInt(ed.slice(0, 4)), edMonth = parseInt(ed.slice(5, 7)), edDay = parseInt(ed.slice(8, 10))
    let age = edYear - dobYear
    if(dobMonth > edMonth){
        age--
    } else if(dobMonth === edMonth){
        if(dobDay > edDay) age--
    }
    return age
}

export const parseEffectiveDate = (date) => {
    if (date === undefined) return -1
    let ed = parseUTC(date), edYear = parseInt(ed.slice(0, 4)), edMonth = parseInt(ed.slice(5, 7))
    let today = parseUTC(Date.now()), todayYear = parseInt(today.slice(0, 4)), todayMonth = parseInt(today.slice(5, 7))
    let res = [`❗ Not enrolled. ✅ The effective date is ${ed}`]
    if(todayYear === edYear){
        if(todayMonth >= edMonth+2){
            res = [`❌ Not enrolled. The effective date ${ed} is more than 2 months in the past.`, `❌ Please update the effective date on the first page of the ER shop and requote.`]
        }
    } else if(todayYear > edYear){
        if(todayMonth+12 >= edMonth+2){
            res = [`❌ Not enrolled. The effective date ${ed} is more than 2 months in the past.`, `❌ Please update the effective date on the first page of the ER shop and requote.`]
        }
    }
    return res
}
