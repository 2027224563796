import React, { useState, useEffect } from "react";
import * as api from "utils/api-primitives.js";
import moment from "moment";
import { startCase } from 'lodash'
import errorAlert from "utils/alert";
import { Controller, useForm } from "react-hook-form";

enum State {
  AL = 'AL', AK = 'AK', AZ = 'AZ', AR = 'AR', CA = 'CA', CO = 'CO', CT = 'CT', DC = 'DC', DE = 'DE', FL = 'FL', GA = 'GA', HI = 'HI', ID = 'ID', IL = 'IL', IN = 'IN', IA = 'IA', KS = 'KS', KY = 'KY', LA = 'LA', ME = 'ME', MD = 'MD', MA = 'MA', MI = 'MI', MN = 'MN', MS = 'MS', MO = 'MO', MT = 'MT', NE = 'NE', NV = 'NV', NH = 'NH', NJ = 'NJ', NM = 'NM', NY = 'NY', NC = 'NC', ND = 'ND', OH = 'OH', OK = 'OK', OR = 'OR', PA = 'PA', RI = 'RI', SC = 'SC', SD = 'SD', TN = 'TN', TX = 'TX', UT = 'UT', VT = 'VT', VA = 'VA', WA = 'WA', WV = 'WV', WI = 'WI', WY = 'WY'
}

export enum FormType {
  carrierApplication = "carrierApplication",
  underwriting = "underwriting",
  waiver = "waiver",
  qualifyingEvent = "qualifyingEvent"
}

export interface FormInterface {
  id?: string
  name?: string
  type?: FormType
  states?: State[]
  carrier?: string
  carrierFormID?: string
  carrierFormDate?: Date
  templateID?: string
}

export type Carrier = string;

const FormCreator: React.FC = () => {
  const [forms, setForms] = useState<FormInterface[]>([])
  const [form, setForm] = useState<FormInterface | undefined>()
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [templates, setTemplates] = useState<any[]>([])
  const formRef = React.createRef<HTMLFormElement>()

  useEffect(() => {
    api.get(`/v2/carriers`).then(setCarriers).catch(errorAlert);
  }, [])

  useEffect(() => {
    api.get(`/v3/integrations/docusign/templates`).then(setTemplates).catch(errorAlert)
    api.get(`/v2/forms`).then(setForms).catch(errorAlert)
  }, [form])

  const { register, handleSubmit, reset, setValue, control } = useForm<FormInterface>({
    defaultValues: {
      ...form,
      carrierFormDate: !form?.carrierFormDate ? undefined : moment.utc(form?.carrierFormDate).format("YYYY-MM-DD")
    }
  })

  const selectAllStates = (event: React.FormEvent<HTMLButtonElement>, v: boolean) => {
    const formEl = formRef.current
    if (formEl) {
      const select = formEl.states as HTMLSelectElement
      for (let idx = 0; idx < select.options.length; idx++) {
        select.options[idx].selected = v
      }
    }
  }

  const toggleState = (event: React.MouseEvent<HTMLOptionElement>) => {
    // Fix Chrome erratic scroll behavior https://stackoverflow.com/a/60660662/10913628
    const el = event.currentTarget
    event.preventDefault()
    event.currentTarget.selected = !event.currentTarget.selected
    const scrollTop = (el.parentNode as HTMLSelectElement)?.scrollTop
    setTimeout(() => (el.parentNode as HTMLSelectElement)?.scrollTo(0, scrollTop), 0)
  }

  const onformdelete = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const formID = form?.id
    if (formID && window.confirm('Are you sure you want to delete this form?')) {
      api.delete_(`/v2/forms/${formID}`).then(() => setForm(form)).catch(errorAlert)
    }
  }

  const onSubmit = (data: any) => {
    if (data.carrierFormDate) {
      data.carrierFormDate = moment.utc(data.carrierFormDate).format('YYYY-MM-DDTHH:mm:ss\\Z')
    }
    const submit = (promise: Promise<any>) => promise.then(setForm).then(() => {
      alert(`Success! Form ${data.id ? 'updated' : 'submitted'}`)
      reset()
      setForm(undefined)
    }).catch(errorAlert)
    if (data.id) {
      submit(api.put(`/v2/forms/${data.id}`, data as any))
    } else {
      submit(api.post(`/v2/forms`, data, true))
    }
  }

  return <>
    <label>Form: </label>
    <select name="id" className='custom-select' onChange={(e) => {
      const _form = forms.find(f => f.id === e.currentTarget.value)
      setForm(_form)
      if (_form) {
        Object.keys(_form).forEach(k => setValue(k, (_form as any)[k]))
      } else {
        reset()
      }
    }} required>
      <option value={undefined}>New Form</option>
      {forms.map((form: FormInterface, index: number) => (
        <option key={index} value={form.id}>
          {form.name}
        </option>
      ))}
    </select>
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <input ref={register()} name='id' type='hidden' />
        <label>Delete form:
          <input className='btn btn-danger btn-sm' type="button" value="X" onClick={onformdelete} disabled={!form?.id} />
        </label>
        <br />
        <label>
          {`Form name: `}
          <input className='form-control' type="text" name='name' ref={register({ required: true })} />
        </label>
        <br />
        <label>
          {`Form type: `}
          <select name="type" className='custom-select' ref={register({ required: true })}>
            <option value={undefined}></option>
            {Object.values(FormType).map((type: string, index: number) => (
              <option key={index} value={type}>
                {startCase(type)} form
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          {`States: `}
          <select name='states' ref={register({ required: true })} style={{ width: 100 }} className='custom-select form-select form-select-lg' multiple>
            {Object.values(State).map((state: string, index: number) => (
              <option key={index} value={state} onMouseDown={toggleState}>
                {state}
              </option>
            ))}
          </select>
        </label>
        <button type='button' className='btn btn-secondary' onClick={e => selectAllStates(e, true)}>Select All</button>
        <button type='button' className='btn btn-secondary' onClick={e => selectAllStates(e, false)}>Select None</button>
        <br />
        <label>
          {`Form carrier name: `}
          <select name="carrier" className='custom-select' ref={register({ required: true })}>
            <option value={undefined}></option>
            {carriers.map((carrier: string, index: number) => (
              <option key={index} value={carrier}>
                {carrier}
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          {`Form carrier ID: `}
          <input className='form-control' name="carrierFormID" type="text" ref={register({ required: true })} />
        </label>
        <br />
        <label>
          {`Form carrier date: `}
          <Controller
            name='carrierFormDate'
            control={control}
            render={(field) => {
              return <input
                {...field}
                value={field.value ? moment.utc(field.value).format("YYYY-MM-DD") : undefined}
                className='form-control'
                name="carrierFormDate"
                type="date"
                max={moment().format("YYYY-MM-DD")}
                placeholder="MM/DD/YYYY"
              />
            }}
          />
        </label>
        <br />
        <label>{'DocuSign Template:'}
          <select name="templateID" className='custom-select' ref={register({ required: true })}>
            <option value={undefined}></option>
            {templates.map((template: any, index: number) => (
              <option key={index} value={template.templateId}>
                {`${template.folderName}/${template.name}`}
              </option>
            ))}
          </select>
        </label>
        <br />
        <input className='btn btn-danger' type="reset" value="Reset" />
        <input className='btn btn-primary' type="submit" value="Submit" />
      </fieldset>
    </form>
  </>
}

export default FormCreator