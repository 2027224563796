import React, { useEffect, useState } from 'react'
import TableRender from '../../utils/TableRender'
import UpdateUserEmail from '../UpdateUserEmail'
import MedicalUnderWritingStatus from '../MedicalUnderwritingStatus'
import { ZohoMembersPanel } from '../Zoho/Panel'
import ExternalId from 'components/ExternalId'
import SupplementalForm from 'utils/supplementalForm'
import { get } from 'utils/api-primitives'
import SalaryForm from 'utils/SalaryForm'
import { parseAge, parseUTC } from './GroupDumpUtils'
import MedicalForm from 'utils/medicalForm'

export default function GroupDumpMember({member, dependents, group, plans, ancillary, splits}) {
    const [loading, setLoading] = useState(true)
    const [memberSupplementalPremiums, setMemberSupplementalPremiums] = useState({})
    const [memberMedicalPremiums, setMemberMedicalPremiums] = useState({})
    const [showSupplementalForm, setShowSupplementalForm] = useState(false)
    const [showMedicalForm, setShowMedicalForm] = useState(false)
    const [salary, setSalary] = useState(member.salary)
    const [showSalaryForm, setShowSalaryForm] = useState(false)
    const [hasSTDplan, setHasSTDplan] = useState(false)
    const [showEditMedicalButton, setShowEditMedicalButton] = useState(false)
    const getMemberPremiums = async() => {
        // medical
        let hasABplans = group.group.planType === 'ageBanded' ? true : false
        if(hasABplans){
          let fetchTieredRates = await get(`/v3/groups/${group.id}/users/memberPremiums/medical`)
          let memberPremiums = fetchTieredRates.premiums.filter(m => m.memberID === member.id)
          setMemberMedicalPremiums(...memberPremiums)
        }
        // supplemental
        let supplementalEditableTypes = ['criticalIllness', 'hospital', 'std']
        let editablePlans = []
        for(const key in member){
            if(supplementalEditableTypes.includes(key)){
                editablePlans.push({key: member[key]})
            }
        }
        const fetchedPremiums = {}
        await editablePlans.forEach(async p => {
            let planType = p.key.plan.type
            let fetch = await get(`/v3/groups/${group.id}/users/memberPremiums/${planType}`)
            let premiums = fetch.premiums.filter(m => m.memberID === member.id)
            fetchedPremiums[planType] = premiums[0]
            setMemberSupplementalPremiums({
                ...fetchedPremiums,
                planType: premiums[0]
            })
            if(planType === 'std') setHasSTDplan(true)
        })

        if(memberMedicalPremiums !== undefined && Object.keys(memberMedicalPremiums).length > 0) setShowEditMedicalButton(true)

        if(member.plan === undefined) setShowEditMedicalButton(false)
        else if(member.plan.premium === undefined)setShowEditMedicalButton(false)
        else if(group.group.planType !== 'ageBanded') setShowEditMedicalButton(false)

        setLoading(false)
    }

    useEffect(() => {
      // running a new group-dump does not reset the member info set in hooks. So when the member changes, via a new group-dump, we want to reset the hooks
      setHasSTDplan(false)
      getMemberPremiums()
      setShowSupplementalForm(false)
      setShowMedicalForm(false)
      setShowSalaryForm(false)
      setSalary(member.salary)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member])

    function mangleMember(member, dependents, group, plans, ancillary, splits) {
        let dental = ancillary && ancillary.dental && ancillary.dental.find((dental) => {
          return dental.plan.id === member.enrolled_dental_plan_id;
        })
        let vision = ancillary && ancillary.vision && ancillary.vision.find((vision) => {
          return vision.plan.id === member.enrolled_vision_plan_id;
        })
        let life = ancillary && ancillary.life && ancillary.life.find((life) => {
          return life.plan.id === member.enrolled_life_plan_id;
        })
        let medical = plans && plans.find(plan => plan.id === member.plan?.id)
      
        let ids = {
          medical: medical?.memberIds?.find(id => id.memberID === member.id)?.externalID,
          dental: dental?.memberIds?.find(id => id.memberID === member.id)?.externalID,
          vision: vision?.memberIds?.find(id => id.memberID === member.id)?.externalID,
          life: life?.memberIds?.find(id => id.memberID === member.id)?.externalID,
        }
        const metadata = member ? member.userMetadata ? member.userMetadata : {} : {}
        let res =  {
          "Name": member.name,
          "Email": <UpdateUserEmail
            member={member}
            dependents={dependents}
          />,
          "phone #": member.phone,
          "Status": (member.waiveReason !== undefined ? `${member.status}: ${member.waiveReason}`: member.status),
          "Medical Underwriting": <MedicalUnderWritingStatus
            medical_underwriting_complete={member.medical_underwriting_complete}
            groupId={group.id}
            memberEmail={member.email}
          />,
          "Job Title": metadata.jobTitle || "–",
          "Hire date": parseUTC(member.hireDate),
          "Term date": member.terminationDate ? parseUTC(member.terminationDate) : "-",
          "Street Address": member.address1 || "–",
          "City": member.city || metadata.city || "–",
          "State": member.state || "–",
          "Zip Code": member.zipCode || member.location,
          "SSN": member.ssn,
          "Age": parseAge(member.dateOfBirth),
          "DoB": parseUTC(member.dateOfBirth),
          "Marital Status": member.marital_status,
          "Gender": member.gender,
          "Salary": salary ? `${Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(salary)}` : "-",
          "Last Tobacco Use Date": parseUTC(member.last_tobacco_use_date),
          "Plan ID": member.plan ? <a href={`#${member.plan.id}`}>{member.plan.id}</a>: "–",
          "Plan Carrier": (member.plan || {}).carrier,
          "Plan Member ID": !!medical && <ExternalId groupID={group.id} plan={{...medical, policyId: ids.medical}} member={member} />,
          "Plan Name": (member.plan || {}).name,
          "Group ID": group.id,
          "Plan Type": (member.plan || {}).type,
          "Enrollment type": member.tier,
          "Employer Contribution": group.owner_payment,
          // composite rates
          "Employee Only Premium": member.plan && member.plan.premium.employee ? member.plan.premium.employee.individual : "",
          "Employee + Spouse Premium": member.plan && member.plan.premium.employee ? member.plan.premium.employee.couple : "",
          "Employee + Child Premium": member.plan && member.plan.premium.employee ? member.plan.premium.employee.singleParent : "",
          "Family Premium": member.plan && member.plan.premium.employee ? member.plan.premium.employee.family : "",
          // age banded rates
          "Employee Premium": member.plan && group.group.rateType === 'ageBanded' ? memberMedicalPremiums.insured_premium : "",
          "Dependent Premium": member.plan && group.group.rateType === 'ageBanded' ? memberMedicalPremiums.dependant_premium : "",
          "Effective Date": parseUTC(group.effectiveDate),
          "Life Plan Name": member.lifePlan && member.lifePlan.plan ? member.lifePlan.plan.name : "–",
          "Life Plan ID": (member && member.enrolled_life_plan_id) || "–",
          "Life Plan Member ID": !!life && <ExternalId groupID={group.id} plan={{...life.plan, policyId: ids.life}} member={member} />,
          "Life Monthly Premium": (member.lifePlan && member.lifePlan.rate) || "–",
          "Dental Plan Name": (dental && dental["plan"] && dental["plan"]["name"]) || "–",
          "Dental Plan ID": (member && member.enrolled_dental_plan_id) || "–",
          "Dental Plan Member ID": !!dental && <ExternalId groupID={group.id} plan={{...dental.plan, policyId: ids.dental}} member={member} />,
          "Dental Monthly Premium": (dental && dental["rates"] && dental["rates"][member.tier]) || "–",
          "Vision Plan Name": (vision && vision["plan"] && vision["plan"]["name"]) || "–",
          "Vision Plan ID": (member && member.enrolled_vision_plan_id) || "–",
          "Vision Plan Member ID": !!vision && <ExternalId groupID={group.id} plan={{...vision.plan, policyId: ids.vision}} member={member} />,
          "Vision Monthly Premium": (vision && vision["plan"] && vision["rates"][member.tier]) || "–",
          "Life?": `${!!member.enrolled_life_plan_id}`,
          "Tax withholding status": (member.userMetadata && member.userMetadata.taxWithholdingStatus && member.userMetadata.taxWithholdingStatus.value) || "–",
          "Household Income": (member.userMetadata && member.userMetadata.householdIncom) || "–",
          "Invited at": parseUTC(member.invited_at),
          "Payment Info Received": (!!(member.paymentInfo && member.paymentInfo.stripePaymentID)).toString(),
          "Payment Status": (member.paymentInfo && member.paymentInfo.paymentStatus) || "–",
          "Last Payment Date": (member.paymentInfo && member.paymentInfo.lastPaymentDate && parseUTC(member.paymentInfo.lastPaymentDate)) || "–",
          "Promo Code ID": (member.paymentInfo && member.paymentInfo.promoCodeId && // See PromoCodes.js for more info
            <a href={`#${member.paymentInfo.promoCodeId}`} onClick={() => (document.getElementById("promocodes").open = true)}>{member.paymentInfo.promoCodeId}</a>) || "–",
          "ID": member.id,
          'Payroll Rate': (splits.length > 0 && "special class rate") || 'payroll rate',
          // supplemental
          //// composite
          'Accident plan': (member.accident && `${member.accident.plan.carrier} - ${member.accident.plan.name}`) || "–",
          'Accident premium': (member.accident && member.accident.rate[member.tier]) || "–",
          'Cancer plan': (member.cancer && `${member.cancer.plan.carrier} - ${member.cancer.plan.name}`) || "–",
          'Cancer premium': (member.cancer && member.cancer.rate[member.tier]) || "–",
          //// age band w/ tiers
          'Critical Illness plan': (member.criticalIllness && `${member.criticalIllness.plan.carrier} - ${member.criticalIllness.plan.name}`) || "–",
          'Critical Illness premium': (memberSupplementalPremiums.criticalIllness && memberSupplementalPremiums.criticalIllness.total_premium) || "–",
          'Hospital plan': (member.hospital && `${member.hospital.plan.carrier} - ${member.hospital.plan.name}`) || "–",
          'Hospital premium': (memberSupplementalPremiums.hospital && memberSupplementalPremiums.hospital.total_premium) || "–",
          'STD plan': (member.std && `${member.std.plan.carrier} - ${member.std.plan.name}`) || "–",
          'STD premium': (memberSupplementalPremiums.std && memberSupplementalPremiums.std.total_premium) || "–"
        }
        if(member.plan !== undefined){
          if(group.group.rateType === 'ageBanded'){
            delete res["Employee Only Premium"]
            delete res["Employee + Spouse Premium"]
            delete res["Employee + Child Premium"]
            delete res["Family Premium"]
          } else {
            delete res["Employee Premium"]
            delete res["Dependent Premium"]
          }
        } else {
          delete res["Employee Premium"]
          delete res["Dependent Premium"]
        }
        if(member.terminationDate === undefined) delete res["Term date"]
        return res
    }

    function mangleDependents(dependents) {
        return dependents.map(dep => {
        return {
            "Name": [dep.firstName, dep.lastName].filter(o => !!o).join(" ") || '–',
            "Relationship": dep.relationship || '–',
            "Age": parseAge(dep.dateOfBirth),
            "DoB": parseUTC(dep.dateOfBirth),
            "Gender": dep.gender || '–',
            "ZIP Code": dep.zip || '–',
            "Last Tobacco": dep.lastTobaccoDate || '–',
            "SSN": dep.ssn || '–',
            "ID": dep.id || '–',
        }
        })
    }

    function employeeSignUpLink(member) {
        if (member.employeeSignUpLink) {
          return <p><a href={member.employeeSignUpLink}>Employee Sign Up Link</a></p>
        } else {
          return <></>
        }
      }
      
    function employeeIMQLink(member) {
        if (member.employeeIMQLink) {
            return <p><a href={member.employeeIMQLink}>Employee IMQ Link</a></p>
        } else {
            return <></>
        }
    }

    function dependentsTable(member) {
        if (!depsTable[0]) return <></>
        return <>
            <h5>{member.name} Dependents</h5>
            <TableRender rows={depsTable} />
          </>
      }

    const memTable = mangleMember(member, dependents, group, plans, ancillary, splits)
    const depsTableInfo = mangleDependents(member.dependents)
    const memKeys = Object.keys(memTable)
    const depKeys = depsTableInfo.length > 0 && Object.keys(depsTableInfo[0])

    const memberTable = [memKeys, Object.values(memTable)]
    const depsTable = [depKeys].concat(depsTableInfo.map(dep => Object.values(dep)))

    const handleForms = (form) => {
      switch(form){
        case 'medical':
          setShowSalaryForm(false)
          setShowSupplementalForm(false)
          setShowMedicalForm(!showMedicalForm)
          break
        case 'supplemental':
          setShowSalaryForm(false)
          setShowMedicalForm(false)
          setShowSupplementalForm(!showSupplementalForm)
          break
        case 'salary':
          setShowSupplementalForm(false)
          setShowMedicalForm(false)
          setShowSalaryForm(!showSalaryForm)
          break
        default:
          break
      }
    }
    
    return (<div key={member.id}>
        <h4 id={member.id}>{member.name}</h4>
        <ZohoMembersPanel member={member} />
        {showEditMedicalButton && <button className='btn btn-success' onClick={() => handleForms("medical")}>edit medical</button>}
        {Object.keys(memberSupplementalPremiums).length > 0 && <button className='btn btn-success' onClick={() => handleForms("supplemental")}>edit supplemental</button>}
        {salary !== undefined && <button className='btn btn-success' onClick={() => handleForms("salary")}>edit salary</button>}
        {showSalaryForm && <SalaryForm setSalary={setSalary} member={[member]} salary={salary} getMemberPremiums={getMemberPremiums} hasSTDplan={hasSTDplan}/>}
        {showSupplementalForm && <SupplementalForm updateTablePremiums={getMemberPremiums} member={[member]} groupID={group.id}/>}
        {showMedicalForm && <MedicalForm updateTablePremiums={getMemberPremiums} member={[member]} groupID={group.id}/>}
        {employeeIMQLink(member)}
        {employeeSignUpLink(member)}
        {!loading && <TableRender rows={memberTable} />}
        {dependentsTable(member)}
      </div>)
}
