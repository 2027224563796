import React, { Component } from 'react';
import auth from 'utils/auth'
import errorAlert from 'utils/alert'

class SignIn extends Component {
  state = {}

  render = () => <>
    <h1>Sign In</h1>
    <form onSubmit={this.submit}>
      <input className='form-control' type='text' value={this.state.mail} onChange={this.handler} name='mail' />
      <input className='form-control' type='password' value={this.state.pass} onChange={this.handler} name='pass' />
      <input className='btn btn-primary' type='submit' />
    </form>
  </>

  handler = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  submit = (event) => {
    event.preventDefault();
    const {mail, pass} = this.state;
    auth.log_in(mail, pass).then(() => window.location.reload()).catch(errorAlert);
  }
}

export default SignIn;
