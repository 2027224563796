import React, { useEffect, useState } from 'react'
import { get, put } from './api-primitives'

export default function SupplementalFormRow({plan, member, groupID, updateTablePremiums}) {
    const [loading, setLoading] = useState(true)
    const [changed, setChanged] = useState(false)
    const [state, setState] = useState({
        memberID: 0,
        total_premium: 0.00,
        spouse_premium: 0.00,
        insured_premium: 0.00,
        children_premium: 0.00
    })
    const [returnValue, setReturnValue] = useState({})
    useEffect(() => {
        getMemberPremiums()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMemberPremiums = async() => {
        let fetch = await get(`/v3/groups/${groupID}/users/memberPremiums/${plan.key.plan.type}`)
        let memberPremiums = fetch.premiums.filter(m => m.memberID === member.id)
        setState(...memberPremiums)
        setReturnValue(fetch)
        setLoading(false)
    }
    const handleChange = (e) => {
        setState({
            memberID: member.id,
            spouse_premium: 0.00,
            children_premium: 0.00,
            insured_premium: e,
            total_premium: e
        })
        setChanged(true)
    }
    const handleSubmit = async(e) => {
        e.preventDefault()
        let obj = returnValue
        let index = -1
        obj.premiums.forEach((m, i)=> {
            if(m.memberID === state.memberID){
                index = i
            }
        });
        obj.premiums[index] = state
        
        await put(`/v3/groups/${groupID}/users/memberPremiums/${plan.key.plan.type}`, obj)
        updateTablePremiums()
        setChanged(false)
    }
    const formatType = (type) => {
        switch (type){
            case 'criticalIllness':
                return 'Critical Illness'
            case 'hospital':
                return 'Hospital'
            default:
                break
            }
    }

  return (
    <div>
        {!loading && 
            <form onSubmit={(e) => handleSubmit(e)} style={{display: 'flex', alignItems: 'center'}}>
                <span className='form-span'>{formatType(plan.key.plan.type)}</span>
                <input className='form-input' value={state.total_premium} onChange={e => handleChange(e.target.value)}/>
                <button button className={changed ? 'btn btn-danger' : 'btn btn-light'}>update</button>
            </form>
        }
    </div>
  )
}
