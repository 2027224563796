import React from 'react';

export default function GroupDumpRemind() {
  //NOTE disabled during this period where DocuSign envelopes are not being automated
  // due to the inability to white-label them
  return <></>;
  /*
  const gentleRemind = () =>
    api.gentlyRemindGroupStragglers(this.state.id).then(() => {
      this.setState({reminded: true})
    }).catch(errorAlert)

  const sendUnsentEmails = () =>
    api.inviteGroupUsers(this.state.id).then(() =>
      this.setState({reminded: true})
    ).catch(errorAlert)

  return (
    <div>
      <h3>Send employee reminder emails</h3>
      <button className="btn btn-success" onClick={gentleRemind} disabled={this.state.reminded}>Send Employee Reminder Emails</button>
      {this.state.reminded && <div>Emails Sent!</div>}
      <h3>Send unsent emails</h3>
      <p>What does this mean? Mainly this exists so you can send stargate invites to employees after everyone in
        the group has had their medical-underwriting-status updated (see buttons below).
      </p>
      <p>For groups that have picked level-funded plans:</p>
      <ul className="list-group">
        <li className="list-group-item">If all members are marked as medically underwritten then the stargate invites will be sent.</li>
        <li className="list-group-item">If not they we will send <b>new</b> DocuSign invites (currently), this costs us money! †</li>
      </ul>
      <p>† This will be fixed in a mysterious future release (ask Bradley).</p>
      <button className="btn btn-primary" onClick={sendUnsentEmails} disabled={this.state.reminded}>Send Unsent Emails</button>
    </div>
  )*/
}
