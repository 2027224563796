import { put, post, get, delete_ } from "utils/api-primitives.js";

const updateMemberEmail = async (memberId, email) => {
  return put(`/v2/support/users/${memberId}/email`, { email });
}

const supportGroups = async (groupId) => {
  return get(`/v2/support/groups/${groupId}`)
}

const supportPromoCodes = async () => get('/v2/support/promocodes')

const zohoGroups = async (page) => get(`/v3/zoho/groups${page ? '?page=' + page : ''}`)

const zohoImportGroups = async (data) => post('/v3/zoho/import/groups', data)

const zohoImportAllGroups = async () => post('/v3/zoho/import/groups/all')

const zohoExportGroups = async (data) => post('/v3/zoho/export/groups', data)

const zohoImportMembers = async (data) => post('/v3/zoho/import/members', data)

const zohoImportAllMembers = async () => post('/v3/zoho/import/members/all')

const zohoExportMembers = async (data) => post('/v3/zoho/export/members', data)

const principalRater = async (groupId) => get(`/v3/principal/groups/${groupId}`)

const principalRaterCensus = async (groupId) => get(`/v3/principal/groups/${groupId}/census`)

const majorCarriers = async () => get('/v2/carriers')

const uninvite = async (groupId) => post(`/v3/cairo/groups/${groupId}/uninvite`, {})

const sidecarGroups = async () => get(`/v3/cairo/integrations/sidecar/groups`)

const sidecarAccounts = async (groupUuid) => get(`/v3/cairo/integrations/sidecar/groups/${groupUuid}`)

const policyIds = async(groupID, data) => post(`/v3/cairo/groups/${groupID}/externalId`, data)

const enrollmentDocsURL = async(groupID, data) => post(`/v3/cairo/groups/${groupID}/enrollmentDocs`, data)

const deleteMedicalPlan = async(groupPlanID) => delete_(`/v3/cairo/groups/plans/${groupPlanID}`)

const restoreMedicalPlan = async(groupPlanID) => post(`/v3/cairo/groups/plans/${groupPlanID}`)

const exports = {
  updateMemberEmail,
  supportGroups,
  supportPromoCodes,
  zohoGroups,
  zohoImportGroups,
  zohoImportAllGroups,
  zohoExportGroups,
  zohoImportMembers,
  zohoImportAllMembers,
  zohoExportMembers,
  principalRater,
  principalRaterCensus,
  majorCarriers,
  uninvite,
  sidecarGroups,
  sidecarAccounts,
  policyIds,
  enrollmentDocsURL,
  deleteMedicalPlan,
  restoreMedicalPlan
};

export default exports;
