import React, { Component } from 'react'
import TableRender from '../../utils/TableRender'
import { startCase, pick, omit } from 'lodash'
import { MedicalPlanEditor } from '../PlanEditor'
import { MedicalPlanCreator } from '../PlanCreator'
import moment from 'moment'
import UploadSBC from 'components/UploadSBC'
import ExternalId from 'components/ExternalId'
import api from 'utils/supportApi'
import AgeBandEditor from 'utils/AgeBandEditor'
export default class GroupDumpMedicalPlans extends Component {

  state = {
    editing: false,
    editingPlan: null,
    uploadingSBCForPlan: null,
    showCreatePlan: false,
    ageBandPlan: null,
    editingAgeBands: false,
    showDeletedMedicalPlans: false
  }

  render() {
    const props = this.props;
    const currentPlans = props.plans.currentPlans
    const deletedPlans = props.plans.deletedPlans
    const showCurrentMedicalPlans = currentPlans && currentPlans.length > 0
    const showDeletedMedicalPlansSection = deletedPlans && deletedPlans.length > 0
    const addPlanButton = this.state.showCreatePlan
      ? null
      : <p><button className="btn btn-success" onClick={this.createPlanCallback}>Add New Custom Plan</button></p>;

    return <>
      { (!showCurrentMedicalPlans && !showDeletedMedicalPlansSection) ? <MedicalPlanCreator shouldRender={this.state.showCreatePlan} groupID={this.props.groupID} /> :
        <> 
          { (showCurrentMedicalPlans || showDeletedMedicalPlansSection) &&
          <>
            { showCurrentMedicalPlans ?
            <>
              <h3>Active</h3>
              <p><em>NOTE:</em> Medical plans deleted in Cairo will not be reflected in the cart but will be shown on the finalize plans page. Do not click Next on the medical plans page. Use the Table of Contents to navigate</p>
              <TableRender rows={planRows(currentPlans, (plan) => ({
                ...pick(manglePlan(plan, props.groupID), ['Carrier', 'Name']),
                'Edit': <>
                  <button className="btn btn-primary" onClick={() => this.editPlanCallback(plan)}>Edit</button>
                  <button className={props.isEnrolled ? "btn btn-danger" : "btn btn-primary"} onClick={() => this.deletePlan(props.isEnrolled, plan)}>Delete</button>
                  <button className="btn btn-primary" onClick={() => this.uploadSBCCallback(plan)}>Upload SBC</button>
                </>,
                'Age Banded?': <> 
                {plan.premium.can_be_age_banded ? "yes": "no"}
                {plan.premium.can_be_age_banded && !plan.isLevelFunded && <button className="btn btn-primary" onClick={() => this.editAgeBandsCallback(plan)}>Edit</button>}
                </>,
                ...omit(manglePlan(plan, props.groupID), ['Carrier', 'Name'])
              }))} />
            </> : <h4>No Active Medical Plans</h4>
          }
            <p><em>NOTE:</em> all the above rates <b>include</b> Prosper Benefits ($29.95)</p>
            <UploadSBC planID={this.state.uploadingSBCForPlan?.id} type='medical'/>
            <MedicalPlanEditor {...this.state.editingPlan} groupID={this.props.groupID} />
            {this.state.editingAgeBands && <AgeBandEditor {...this.state.ageBandPlan} plan={this.state.ageBandPlan} groupID={this.props.groupID} handleCloseAgeBandsForm={this.handleCloseAgeBandsForm}/>}
            <MedicalPlanCreator shouldRender={this.state.showCreatePlan} groupID={this.props.groupID} />
            </> }
          { showDeletedMedicalPlansSection && <>
          <div className='section-header'>
            <h3>Deleted {"(" + deletedPlans.length + ")"}</h3>
            <button className='btn btn-success show-hide-button' onClick={() => this.handleShowHideButton(this.state.showDeletedMedicalPlans)}>{this.state.showDeletedMedicalPlans ? 'Hide' : 'Show'}</button>
          </div>
            <p><em>NOTE:</em> Medical plans deleted in Cairo will not be reflected in the cart but will be shown on the finalize plans page. Do not click Next on the medical plans page. Use the Table of Contents to navigate</p>
          {this.state.showDeletedMedicalPlans &&
          <TableRender rows={planRows(deletedPlans, (plan) => ({
            ...pick(manglePlan(plan, props.groupID), ['Carrier', 'Name']),
            'Edit': <>
              <button className={props.isEnrolled ? "btn btn-danger" : "btn btn-primary"} onClick={() => this.restorePlan(props.isEnrolled, plan)}>Restore</button>
            </>,
            ...omit(manglePlan(plan, props.groupID), ['Carrier', 'Name'])
          }))} />}
          </>
          }
        </>
      }
      {addPlanButton}
    </>
  }

  handleShowHideButton = (bool) => {
    this.setState({showDeletedMedicalPlans: !bool})
  }

  editPlanCallback = (plan) => {
    this.setState({ editingPlan: plan })
  }
  
  editAgeBandsCallback = (plan) => {
    if(this.state.editingAgeBands === true){
      this.handleCloseAgeBandsForm()
      setTimeout(() => {
        this.setState({ ageBandPlan: plan, editingAgeBands: true })
      }, 500)
    } else {
      this.setState({ ageBandPlan: plan, editingAgeBands: true })
    }
  }

  handleCloseAgeBandsForm = () => {
    this.setState({ ageBandPlan: null, editingAgeBands: false })
  }

  deletePlan = (isEnrolled, plan) => {
    isEnrolled ? alert("This group is currently marked as enrolled. To delete or restore plans, please change the group's pipeline status in Zoho or the dashboard.") : api.deleteMedicalPlan(plan.groupPlanID).then(() => { window.location.reload() })
  }

  restorePlan = (isEnrolled, plan) => {
    isEnrolled ? alert("This group is currently marked as enrolled. To delete or restore plans, please change the group's pipeline status in Zoho or the dashboard.") : api.restoreMedicalPlan(plan.groupPlanID).then(() => { window.location.reload() })
  }

  uploadSBCCallback = (plan) => {
    this.setState({ uploadingSBCForPlan: plan })
  }

  createPlanCallback = () => {
    this.setState({ showCreatePlan: true })
  }
}

export const planRows = (plans, mangleFn = manglePlan) => {
  const planRows = plans.map(mangleFn);
  const keys = Object.keys(planRows[0]);
  const rows = [keys];
  planRows.forEach(row => rows.push(keys.map(key => row[key])));
  return rows;
}

export const manglePlan = (plan, groupID) => {
  if (!plan.quote) plan.quote = {};
  if (!plan.perMemberRates) plan.perMemberRates = {};

  return {
    "Carrier": plan.carrier,
    "Name": plan.name,
    "Total Premium": plan.premium ? plan.premium.employer : "–",
    "ee": plan.premium ? plan.premium.employee.individual : "–",
    "ee+spouse": plan.premium ? plan.premium.employee.couple : "–",
    "ee+child": plan.premium ? plan.premium.employee.singleParent : "–",
    "ee+family": plan.premium ? plan.premium.employee.family : "–",
    "OOP Max": plan.oopMax || "–",
    "Deductible": plan.deductible || "–",
    "Type": plan.type,
    "Level Funded": plan.isLevelFunded ? "Yes" : "No",
    "SBC": plan.sbc ? <a href={plan.sbc} target="_blank" rel="noopener noreferrer">SBC</a> : "–",
    "Coinsurance": plan.coinsurance || "–",
    "Copay": plan.copay ? <pre>{Object.keys(plan.copay).map(key => startCase(key) + ": " + plan.copay[key]).join("\r\n")}</pre> : "–",
    "Prescription": plan.prescription ? <pre>{Object.keys(plan.prescription).map(key => startCase(key) + ": " + plan.prescription[key]).join("\r\n")}</pre> : "–",
    "Selected at": plan.created_at ? moment(plan.created_at).format('MMMM Do YYYY, h:mm:ss a') : "–",
    "ID": <span id={plan.id}>{plan.id}</span>,
    "Policy #": <ExternalId groupID={groupID} plan={plan} />,
    "Deleted On": plan.deleted_at ? moment(plan.deleted_at).format('MMMM Do YYYY, h:mm:ss a') : "–"
  }
}

