import React, { Component } from 'react';
import './App.css';
import errorAlert from 'utils/alert'
import auth from 'utils/auth'
import CardWrapper from './utils/CardWrapper'
import GroupDump from './components/GroupDump'
import GroupRenewal from './components/GroupRenewal'
import GroupSearch from './components/GroupSearch'
import Report from './components/Report'
import SignIn from './components/SignIn'
import Tally from './components/Tally'
import Themer from './components/Themer'
import Forms from './components/Forms'
import PromoCodes from './components/PromoCodes'
import Principal from './components/Principal'
import ProducerDump from './components/ProducerDump'
import InternalQuote from './components/InternalQuote'
import SidecarGroups from 'components/Sidecar/SidecarGroups';
import SidecarAccounts from 'components/Sidecar/SidecarAccounts';

class App extends Component {
  render() {
    if (!auth.is_logged_in())
      return <SignIn />


    return <>
      <h1>Cairo</h1>
      <Themer />
      <ul className="list-group">
        <li className="list-group-item">Do not share information from Cairo outside the company without explicit supervisor permission.</li>
        <li className="list-group-item">Do not print anything from Cairo.</li>
        <li className="list-group-item">Memorizing things from Cairo is acceptable, but please scramble and mash your brain before talking to competitors.</li>
      </ul>

      <CardWrapper title='Tally'>
        <Tally />
      </CardWrapper>

      <CardWrapper title='Group Search'>
        <GroupSearch />
      </CardWrapper>

      <CardWrapper title='Group Dump'>
        <GroupDump />
      </CardWrapper>

      <CardWrapper title='Group Renewal'>
        <GroupRenewal />
      </CardWrapper>

      <CardWrapper title='Producer Dump'>
        <ProducerDump />
      </CardWrapper>

      <CardWrapper title='Internal Quoting'>
        <InternalQuote />
      </CardWrapper>

      <CardWrapper title='Principal Rater'>
        <Principal />
      </CardWrapper>

      <CardWrapper title='Sidecar Groups'>
        <SidecarGroups />
      </CardWrapper>

      <CardWrapper title='Sidecar Accounts'>
        <SidecarAccounts />
      </CardWrapper>

      <CardWrapper title='Promo Codes'>
        <PromoCodes />
      </CardWrapper>

      <CardWrapper title='Forms'>
        <Forms />
      </CardWrapper>

      <CardWrapper title='Reports'>
        <Report />
      </CardWrapper>

      <CardWrapper title='Sign Out'>
        <p>In case you need this for some reason.</p>
        <button className="btn btn-primary" onClick={this.signOut}>Sign Out</button>
      </CardWrapper>
    </>
  }

  signOut = () =>
    auth.log_out().then(() => window.location.reload()).catch(errorAlert)
}

export default App;
