import React from "react";
import { useForm } from "react-hook-form";
import { useToggle } from 'react-use';
import errorAlert from "utils/alert";
import api from 'utils/supportApi'

interface Props {
  groupID: string
  plan: {
    id: string
    policyId?: string
  }
  member?: {
    id: string
  }
}

const ExternalId: React.FC<Props> = ({ groupID, plan, member }) => {
  const [editable, toggleEditable] = useToggle(false);
  const form = useForm({ defaultValues: { planID: plan.id, groupID, policyId: plan.policyId, memberID: member?.id }})

  return editable
    ? <form onSubmit={form.handleSubmit(submit)}>
        <input type='hidden' name='planID' ref={form.register} />
        {!!member && <input type='hidden' name='memberID' ref={form.register} />}
        <input className='form-control' type='text' name='policyId' ref={form.register} placeholder={`${!member ? 'Policy' : 'Member'} ID`} />
      </form>
    : <span onClick={toggleEditable}>{plan.policyId || '-'}</span>

  async function submit(data: any) {
    try {
      await api.policyIds(groupID, data)
      alert('ID set. Reload to see updates.')
    } catch (error) {
      errorAlert(error)
    } finally {
      toggleEditable()
    }
  }
};


export default ExternalId;
