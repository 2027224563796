import React, { Component } from "react";

class CardWrapper extends Component {
  render = () => {
    return (
      <div className='card'>
        <div className='card-header bg-info'>
          <h2>{this.props.title}</h2>
        </div>
        <div className='card-body border border-primary'>
          {this.props.children}
        </div>
      </div>
    );
  };

  handler = (event) => {
    localStorage.setItem('cairoTheme', event.target.value)
    this.setState({ theme: event.target.value })
  };
}

export default CardWrapper;
