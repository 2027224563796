import { thousandsComma } from 'utils/AncillaryItemUtilities'
import numeral from 'numeral'
import { moneyString, moneyNumber } from './PremiumCalculators';
export { moneyString, moneyNumber } from './PremiumCalculators';

export const centsFormat = '$0,0.00';
export const dollarsFormat = '$0,0';

export const isDollar = (amount) => {
  return amount.startsWith('$');
}

export const isPercentage = (amount) => {
  return amount.endsWith("%");
}

export const totalEmployerCost = (group, planPremium) => {
  let contribution = group.owner_payment || "0%";

  // Remove $ in front of premium string and comma in thousands place if it exists
  planPremium = moneyNumber(planPremium);
  if (typeof contribution !== "string") {
    return "$0";
  } else if (isDollar(contribution)) {
    let value = numeral(contribution).value();
    const total = value * group.member_count;
    return moneyString(total)
  } else if (isPercentage(contribution)) {
    const value = numeral(contribution);
    const total = value.multiply(planPremium * group.member_count);
    return moneyString(total);
  } else {
    return "$0";
  }
}

export const displayEmployerCost = (premium, employees, contribution) => {
  // Remove $ in front of premium string and comma if it exists
  premium = moneyNumber(premium)

  if (!employees || employees === 0 || contribution === 0 || contribution === "$0" || contribution === "0%") {
    return 0;
  }

  if (isDollar(contribution)) {
    let value = numeral(contribution).value();
    let total = value >= premium ? premium * employees : value * employees
    return moneyNumber(total);
  } else {
    const value = numeral(contribution).value();
    const costToEmployerPerEmployee = premium * value;
    const total = costToEmployerPerEmployee * employees;
    return moneyNumber(total);
  }
}

export const employeeTier = (deps) => {
  let hasPartner = deps.some(dep => dep.relationship === 'spouse' || dep.relationship === 'life_partner')
  let hasChild = deps.some(dep => dep.relationship === 'child')

  let tier;
  if (hasPartner && hasChild) {
    tier = 'family'
  } else if (hasPartner) {
    tier = 'couple'
  } else if (hasChild) {
    tier = 'singleParent'
  } else {
    tier = 'individual'
  }
  return tier
}

export const displayEmployeeCost = (premium, employees, contribution) => {
  premium = moneyNumber(premium);
  return (premium * employees - displayEmployerCost(premium, employees, contribution))
}

// Used to remove unnecessary parts from vericred's string to display in-network data only
export const getInNetworkSpecsFromString = (str, handleDeductible, handleRx, handleCopay) => {
  let parts;
  let inNetworkPart;

  // Handles all RX and Copay cases regardless of LF or not
  // Returns the string in between the in network and out of network part of the string
  if ((handleRx) || (handleCopay)) {
    // Get rid of out of network data at the end
    parts = str.split('/ ')
    // Get rid of "in network:" text in the front
    parts = parts[0].split(':')
    inNetworkPart = parts[1];
    return inNetworkPart;
  } else {
    parts = str.split(' / ');
    parts = parts.map(part => part.replace(/\D/g, ""));
    // Add back the comma for the thousands place
    inNetworkPart = thousandsComma(parts[0]);
  }

  // Deductibles are 'included in medical' if they don't list a cost
  if (parts[0] === '0' && handleDeductible) {
    return "Included in Medical"
  }

  // Just in case
  if (parts[0] === '0') {
    return "Not applicable"
  }

  // Deductible/Indiv OOP Max case
  return `$${inNetworkPart}/yr`;
}

// Remove decimals in deductible and OOP Max
export const cleanDeductibleOop = (amount) => {
  return moneyString(amount);
}

export const getInNetworkSpecsAsNum = (str, handleDeductible, handleRx, handleCopay) => {
  let dollarString = getInNetworkSpecsFromString(str, handleDeductible, handleRx, handleCopay)
  let amt = parseInt(dollarString.replace('$', '').replace('/yr', '').replace(',', ''));
  return isNaN(amt) ? 0 : amt
}

export const cleanContributionString = (amount) => {
  if (amount === undefined || amount === null) return '0%'
  if (typeof (amount) !== 'string') throw new Error(`Bad input ${amount}`)

  if (isPercentage(amount))
    return numeral(amount).multiply(100).value();
  else
    return moneyNumber(amount);
}

