import { startCase } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { $enum } from "ts-enum-util";
import errorAlert from "utils/alert";
import { get, post } from "utils/api-primitives";
import TableRender from "utils/TableRender";

enum Carrier {
  principal = "d0003042-eaae-4491-b219-4825456a3d16",
  guardian = "c699414d-fd1c-4de4-86f0-66dfff2276c4",
  beam = "9a0a7437-4097-4251-9e71-85384c0eb1c9",
}

interface Props {
  group: any; // TODO add types
}

const NoyoGroup: React.FC<Props> = ({ group: _group }) => {
  const { group } = _group;
  const [groupConnection, setGroupConnection] = useState();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [carrierId, setCarrierId] = useState<string>();
  const [carrierGroupId, setCarrierGroupId] = useState<string>();

  useEffect(() => {
    get(`/v3/integrations/noyo/group/${group.id}/connection`)
      .then((conn) => {
        setGroupConnection(conn);
        setCarrierId((conn.carrier_id as string).toLowerCase());
        setCarrierGroupId(
          (conn.group_data?.carrier_group_id as string).toUpperCase()
        );
        setDisabled(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [group.id]);

  function makeRows() {
    const rows = [];
    const heading = mangleGroupConnection({});
    rows.push(Object.keys(heading));
    rows.push(Object.values(mangleGroupConnection(groupConnection || {})));
    return rows;
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = {
      carrierId,
      carrierGroupId,
    };
    post(`/v3/integrations/noyo/group/${group.id}/connection`, data)
      .then((conn) => {
        setGroupConnection(conn);
        setCarrierId((conn.carrier_id as string).toLowerCase());
        setCarrierGroupId(
          (conn.group_data?.carrier_group_id as string).toUpperCase()
        );
        setDisabled(true);
      })
      .catch(errorAlert);
  }

  return (
    <div id="noyo_group">
      <h3>Noyo Group Connection</h3>
      <form onSubmit={onSubmit}>
        <fieldset disabled={disabled}>
          <label>
            Carrier:
            <select
              placeholder="Carrier"
              className="custom-select"
              name="carrierId"
              value={carrierId}
              onChange={(e) => setCarrierId(e.currentTarget.value)}
              required
            >
              <option key="0" value={undefined}></option>
              {$enum(Carrier).map((v, k) => (
                <option key={k} value={v}>
                  {startCase(k)}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            Carrier Group ID:
            <input
              placeholder="Carrier Group ID"
              className="form-control"
              type="text"
              name="carrierGroupId"
              value={carrierGroupId}
              onChange={(e) => setCarrierGroupId(e.currentTarget.value)}
              required
            />
          </label>
          <br />
          <input className="btn btn-primary" type="submit" value="Submit" />
        </fieldset>
      </form>
      {groupConnection && <TableRender rows={makeRows()} />}
    </div>
  );
};

const mangleGroupConnection = (conn: any) => {
  return {
    Status: conn.status || "–",
    "Carrier Group ID": conn.group_data?.carrier_group_id || "–",
    "Group Name": conn.group_data?.group_name || "–",
    "Carrier ID": conn.carrier_id ? `${startCase(Array.from($enum(Carrier).entries(), (v) => ({ k: v[0], v: v[1] })).find(({ v }) => v.toLowerCase() === conn.carrier_id.toLowerCase())?.k)} (${conn.carrier_id})` : "–",
    "Request Type": conn.request_type || "–",
    "Group ID": conn.group_id || "–",
    "Connection ID": conn.id || "–",
    "Organization ID": conn.organization_id || "–",
    "Setup Result": JSON.stringify(conn.setup_result) || "–",
    Created: conn.created
      ? moment.unix(conn.created).format("dddd, MMMM Do, YYYY h:mm:ss A")
      : "–",
    Modified: conn.modified
      ? moment.unix(conn.modified).format("dddd, MMMM Do, YYYY h:mm:ss A")
      : "–",
    Version: conn.version || "–",
  };
};

export default NoyoGroup;
