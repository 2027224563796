import React, { useState } from 'react'
import api from 'utils/api'
import { useForm } from 'react-hook-form'
import errorAlert from 'utils/alert'

interface Props {
  planID?: string
  type?: 'medical' | 'dental' | 'vision' | 'life' | 'disability'
  flagFunc?: () => void
  reload: boolean
}

const UploadSBC: React.FC<Props> = ({ planID, type, flagFunc, reload }) => {
  const [disabled, setDisabled] = useState<boolean>()
  const form = useForm({ defaultValues: { file: null }})
  
  if (!planID || !type) {
    // console.error('Need PlanID and Type to upload SBC')
    return <></>
  }

  return <form onSubmit={form.handleSubmit(submit)}>
    <fieldset disabled={disabled}>
      <input className='form-control-file' name='file' type='file' placeholder='File' ref={form.register} required/>
      <input className='btn btn-primary' type="submit" value="Submit"/>
    </fieldset>
  </form>

  async function submit(data: any) {
    try {
      setDisabled(true)
      const formData = new FormData();
      formData.append('file', data.file[0])
      formData.append('type', type!)
      const link = await api.uploadSBC(planID, formData)
      alert(`SBC Sucessfully Uploaded\nLink: ${link}`)
      flagFunc && flagFunc()
      reload && window.location.reload()
    } catch (error) {
      errorAlert(error)
    } finally {
      setDisabled(false)
    }
    
  }
}

export default UploadSBC