import moment, { Moment } from 'moment'

export function utcMidnightToLocalMidnight(input: any): Date {
    if (typeof input !== 'string') return input
    input = moment(input).local()
    const offset = input.utcOffset()
    return input.minutes(-offset).toDate()
  }
  
  export function localMidnightToUTCMidnight(input: Date | Moment | undefined): Moment | undefined {
    if (!input) return input
    if (!moment.isMoment(input)) input = moment(input).local()
    if (!input.isLocal()) input = input.local()
    const offset = input.utcOffset()
    return input.minutes(offset)
  }
  
  export function localMidnightToPharaohFormat(input: Date | Moment | undefined): string | undefined {
    return localMidnightToUTCMidnight(input)?.format()
  }
  