import React, { Component } from 'react';
import api from '../utils/supportApi'
import errorAlert from '../utils/alert'
import TableRender from '../utils/TableRender'

class PromoCodes extends Component {
  state = {
    promocodes: []
  }

  componentDidMount() {
    api.supportPromoCodes()
      .then(promos => this.setState({ promocodes: promos }))
      .catch(errorAlert)
  }

  manglePromo = (promo) => ({
    'Promo Code': <span id={promo.id}>{promo.code}</span>,
    'Code Remaining': promo.remaining,
    'Code Uses': promo.uses,
    'ID': promo.id
  })

  render() {
    const { promocodes } = this.state
    let header = []
    if (promocodes.length > 0) {
      header = Object.keys(this.manglePromo(promocodes[0]))
    }
    const rows = [header, ...promocodes.map(this.manglePromo).map(Object.values)]

    return promocodes.length > 0
    // NOTE: id required here so that GroupDump.Members is able to expand `details` onClick
      ? <details id={'promocodes'}>
        <TableRender rows={rows} />
      </details>
      : <p>No promo codes available</p>
  }
}

export default PromoCodes;
