import * as api from './api-primitives'

//// public API
async function sign_in(user, pass) {
  console.assert(!is_signed_in())

  return api.post("/users/sessions", {
    email: user,
    password: pass,
  }, false).then(rsp => {
    api.token.set(rsp.token);
    return rsp;
  });
}

async function anonSignUp(obj) {
  const rsp = await api.post(`/users/anon`, obj);
  api.token.set(rsp.token);
}

async function anonEmailSignUp(obj, no_auth = true) {
  const rsp = await api.post(`/v2/users/gatekeeper`, obj, no_auth);
  api.token.set(rsp.token);
}

async function completeSignUp(email, firstName, lastName, password, confirmPassword, emailedToken) {
  return api.post('/users', {
    email, firstName, lastName, password, confirmPassword, emailedToken
  }, false).then(rsp => {
    api.token.set(rsp.token);
    return {id: rsp.groupId, name: rsp.groupName};
  });
}

async function emailUserAnubisSignUp() {
  await api.post('/users/anubis/invite')
}

async function exchangeTokenForPassword(token, newPassword) {
  const rsp = await api.post(`/users/reset-password/${token}`, {
    password: newPassword
  }, false)
  api.token.set(rsp.token);
  return rsp;
}

async function sign_out() {
  if (!is_signed_in())
    return Promise.resolve();

  function done() {
    api.token.clear();
    localStorage.clear();  //NOTE just being thorough!
  }
  try {
    await api.delete_("/users/sessions");
    done();
  } catch(e) {
    done();
    throw e;
  }
}

async function reset_password(email) {
  return api.post("/users/reset-password", {
    email: email
  }, false);
}

function is_signed_in() {
  return api.token.is_valid();
}

/// current user object
async function me() {
  return api.get("/users");
}

/// 422 if either value is invalid, or if the currentPassword is incorrect.
function change_password(current_pass, new_pass) {
  return api.post("/users/change-password", {
    current: current_pass,
    new: new_pass
  });
}

// Create a new user account for small broker portal/obelisk
async function createSmallBrokerUserAccount(payload) {
  return api.post('/v2/obelisk/users', payload).then(rsp => {
    api.token.set(rsp.token)
  })
}

//// exports

const auth = {
  is_logged_in: is_signed_in,
  log_in: sign_in,
  log_out: sign_out,
  reset_password: reset_password,
  me: me,
  change_password: change_password,
  completeSignUp,
  exchangeTokenForPassword,
  emailUserAnubisSignUp,
  anonSignUp,
  createSmallBrokerUserAccount,
  anonEmailSignUp
};

export default auth;
