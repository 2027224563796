import React, { Component } from 'react';
import errorAlert from '../utils/alert'
import { get } from 'utils/api-primitives'
import fileDownload from 'js-file-download';

class Report extends Component {
  state = {
    loading: false
  }

  render = () => <>
    <button className="btn btn-primary" onClick={this.gimme}>Daily Report</button>
    <button className="btn btn-success" onClick={this.zoho}>Zoho Import</button>
    {this.state.loading && <span>Loading…</span>}
  </>

  gimme = () => {
    this.setState({ loading: true });

    get('/support/report').then(rsp =>
      rsp.blob()
    ).then(data =>
      fileDownload(data, `Candor-Report.${dateString()}.csv`)
    ).catch(errorAlert).finally(() =>
      this.setState({ loading: false })
    );
  }

  zoho = () => {
    this.setState({ loading: true });

    get('/v2/support/report/zoho').then(rsp =>
      rsp.blob()
    ).then(data =>
      fileDownload(data, `Zoho-Import.${dateString()}.csv`)
    ).catch(errorAlert).finally(() =>
      this.setState({ loading: false })
    );
  }
}

function dateString() {
  const now = new Date();
  const dy = ("0" + now.getDate()).slice(-2);
  const mn = ("0" + (now.getMonth() + 1)).slice(-2);
  return `${now.getFullYear()}${mn}${dy}`;
}

export default Report;
