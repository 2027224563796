function sanitize(obj) {
  if (obj.errors) { // neith graphql errors
    return obj.errors.map(msg => msg.message).join("\r\n\r\n");
  } else {
    // eslint-disable-next-line no-mixed-operators
    return obj.reason || obj.json && (obj.json.reason || obj.json.message) || obj.message || `${obj}`;
  }
}

function errorAlert(title, obj) {
  if (obj === undefined) {
    if (typeof(title) == "object") {
      const msg = sanitize(title);
      console.error(msg, title);
      window.alert(msg);
    } else {
      console.error(title);
      window.alert(title);
    }
  } else {
    const msg = sanitize(obj);
    console.error(msg, title, obj);
    window.alert(`${title}\n\n${msg}`);
  }
}

export default errorAlert;
