import React, { useState } from 'react';
import api from '../utils/supportApi'
import errorAlert from '../utils/alert'
import TableRender from '../utils/TableRender'
import { startCase } from 'lodash'
import { moneyString } from '../utils/PremiumCalculators'
import moment from 'moment'

const Principal: React.FC = () => {
    const [rates, setRates] = useState<any>()
    const [groupId, setGroupId] = useState<string>('')

    async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const groupId = data.get('groupId') as string
        setGroupId(groupId)
        api.principalRater(groupId).then(rsp => setRates(rsp)).catch(errorAlert)
    }

    function manglePlans(rates: any) {
        return [['Plan', 'Rates'], ...rates.map((rate: any) => [`Plan ${rate.plan}`, <pre>{Object.keys(rate.rate).map(key => {
            if (rate?.rate?.[key]?.finalRate) { // VTL
                const band = rate.rate[key].band
                return `${band.min}-${band.max}: $${rate.rate[key].finalRate}\n`
            } else { // The rest
                return `${startCase(key)}: ${moneyString(rate.rate[key], 2)}\n`
            }
        })}</pre>])]
    }

    function caseInfo(rates: any) {
        let group: any = (rates?.dental || rates?.vision)?.find((plan: any) => plan?.group)?.group || rates?.vtl?.group
        if (group) {
            return <>
                <h3>Case Info</h3>
                <label><b>Group Name:</b> {group?.name}</label><br />
                <label><b>Effective Date</b>: {moment(group?.effectiveDate).format('MM/DD/YYYY')}</label><br />
                <label><b>Zip Code</b>: {group?.zipCode}</label><br />
                <label><b>SIC Code</b>: {group?.industrySICCode}</label><br />
            </>
        } else {
            return <></>
        }
    }

    function onCopyCensus(event: React.MouseEvent<HTMLButtonElement>) {
        api.principalRaterCensus(groupId).then(async(rsp: Response) => {
            const census = await rsp.text()
            return navigator.clipboard.writeText(census)
        }).then(() => alert('Census copied!\n\nYou can now select the census area in the rater and paste your clipboard.\nMANDATORY! DELETE OLD ENTRIES IN THE RATER!')
        ).catch(errorAlert)
    }

    return <>
        <form onSubmit={onSubmit}>
            <input className='form-control' name='groupId' type='text' placeholder='Group ID' />
            <input className='btn btn-primary' type='submit' value='Submit' />
        </form>
        {rates &&
        <>
            <br />
            <button className="btn btn-primary" type='button' onClick={onCopyCensus}>Copy census</button>
            {caseInfo(rates)}
            {['dental', 'vision', 'vtl'].map(key => rates[key] && <>
                <h3>{startCase(key.toUpperCase())}</h3>
                {key === 'vtl' && <p>Monthly Rate per $1,000 of Benefit</p>}
                <TableRender rows={manglePlans(key === 'vtl' ? [rates[key]] : rates[key])} />
            </>)
            }
        </>
        }
    </>
}

export default Principal