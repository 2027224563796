import React, { useState, useEffect } from 'react'
import './forms.css'
import { get, put } from './api-primitives'

export default function MedicalForm({member, groupID, updateTablePremiums}) {
    let [mem] = member
    const [changed, setChanged] = useState(false)
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        memberID: mem.id,
        total_premium: 0, // ee + dependent
        spouse_premium: 0,
        insured_premium: 0, // ee
        children_premium: 0,
        dependant_premium: 0 // dependent
    })
    const [returnValue, setReturnValue] = useState({})

    useEffect(() => {
        getMemberPremiums()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMemberPremiums = async() => {
        let fetch = await get(`/v3/groups/${groupID}/users/memberPremiums/medical`)
        let memberPremiums = fetch.premiums.filter(m => m.memberID === mem.id)
        setState(...memberPremiums)
        setReturnValue(fetch)
        setLoading(false)
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault()
        let obj = returnValue
        let index = -1
        obj.premiums.forEach((m, i)=> {
            if(m.memberID === mem.id){
                index = i
            }
        });
        obj.premiums[index] = state
        await put(`/v3/groups/${groupID}/users/memberPremiums/medical`, obj)
        updateTablePremiums()
        setChanged(false)
    }

    const parseVal = val => val[0] === "$" ? parseFloat(val.toString().split('').filter(char => char !== ',').slice(1).join('')) : parseFloat(val.toString().split('').filter(char => char !== ',').join(''))

    const handleChange = (val, key) => {
        let obj = state
        if (key === 'ee') {
            setState({
                ...obj,
                insured_premium: val,
                total_premium: parseVal(val) + parseVal(state.dependant_premium)
            })
        } else if (key === 'dep'){
            setState({
                ...obj,
                total_premium: parseVal(val) + parseVal(state.insured_premium),
                dependant_premium: val
            })
        }
        setChanged(true)
    }
    
  return (
    <div className='form-container'>
        <h4>Update Medical Rates for {mem.name}</h4>
            <table className='table table-responsive table-striped'>
                {loading ? 
                    <p>loading</p> :
                    <div>
                        <form onSubmit={(e) => handleSubmit(e)} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <span className='form-span'>employee premium:</span>
                                <input className='form-input' value={state.insured_premium || 0} onChange={e => handleChange(e.target.value, 'ee')}/>
                            </div>
                            { mem.dependents.length > 0 && <div style={{ display: 'flex', alignItems: 'center'}}>
                                <span className='form-span'>dependents:</span>
                                <input className='form-input' value={state.dependant_premium || 0} onChange={e => handleChange(e.target.value, 'dep')}/>
                            </div> }
                            <button button className={changed ? 'btn btn-danger' : 'btn btn-light'}>update</button>
                        </form>
                    </div>
                }
            </table>
    </div>
  )
}
