import React, { useEffect, useState } from 'react'
import { get } from 'utils/api-primitives'
import { parseAgeAtEffectiveDate, parseEffectiveDate, parseUTC } from './GroupDumpUtils'
import './groupDump.css'


export default function GroupDumpAuditor({members, group}) {
    const [showAuditor, setShowAuditor] = useState(true)
    const groupHasEffectiveDate = group.group.effectiveDate ? true : false
    const [state, setState] = useState({
        memberAges: {category: ["Member ages between 18-65 years old"], response: ["✅"], visible: true, button: false},
        dependentAges: {category: ["Dependent ages between 1-18 years old"], response: ["✅"], visible: true, button: false},
        memberLocation: {category: [`Members located in group state${group.states.length > 1 ? "s" : ""} (${group.states.join(", ")})`], response: ["✅"], visible: false, button: true},
        memberCountyCodes : {category: [`Member county codes`], response: ["✅"], visible: false, button: true},
        groupFipsCodes : {category: [`Group ZIP code: ${group.zipCode}`, `Group Assigned FIPS code: ${group.countyID} (${group.countyName})`], response: [""], visible: true, button: false},
        memberPlanIDs: {category: ["Members planID's in new_group_plans table"], response: ["✅"], visible: true, button: false},
        memberUnderwriting: {category: ["Members underwriting complete"], response: ["✅"], visible: false, button: true},
        memberInvites: {category: ["Member invites sent"], response: ["❌ Member invites not sent"], visible: true, button: false},
        managerInvite: {category: ["Manager invite sent"], response: ["❌ Manager invite not sent"], visible: true, button: false},
        memberStargate: {category: ["Members visited the shop"], response: ["✅"], visible: false, button: true},
        groupEffectiveDate: {category: ["Group is enrolled.", `${groupHasEffectiveDate ? `If not, the group effective date is NOT > 2 months past -> ${parseUTC(group.group.effectiveDate)}` : 'GROUP HAS NO EFFECTIVE DATE'}`], response: [`${groupHasEffectiveDate ? '' : '❌ GROUP HAS NO EFFECTIVE DATE'}`], visible: false},
    })

    const checkMembers = async() => {
        let memberAgesRes = [], dependentAgesRes = [], memberCountiesRes = [], membersWithMultCounties = 0, memberLocationsRes = [], memberLocationIssues = 0, memberUnderwritingRes = [], memberStargateRes = [], membersVisitedStargate = 0, memberPlanIDRes = [], membersWithUnderwriting = 0
        let auditData = await get(`/v3/cairo/groups/${group.id}/audit`)
        let newGroupPlanIDs = auditData.newGroupPlans.map(plan => plan.planID)
        let memberStargateEmails = auditData.emails.map(user => user.email) // cross reference users table to check which members have visited stargate
        let managerInvites = false
        let memberInvites = false
        let invites = auditData.invites
        for (let i = 0; i < invites.length; i++){
            if (memberInvites & managerInvites) i = invites.length - 1
            if (invites[i].venue === 0) managerInvites = true
            if (invites[i].venue === 2) memberInvites = true
        }
        let invites_deleted = auditData.invites.map(invite => invite.deleted_at)

        // if the group doesn't have an effective date we cannot check member & dependent ages
        if(!groupHasEffectiveDate){
            memberAgesRes = ([<span>❌ GROUP HAS NO EFFECTIVE DATE - cannot check member ages until we have an effective date.</span>])
            dependentAgesRes = ([<span>❌ GROUP HAS NO EFFECTIVE DATE - cannot check dependent ages until we have an effective date.</span>])
        }

        members.forEach(mem => {
            // check member and dependent ages
            if(groupHasEffectiveDate){
                // check member ages
                let memberAge = parseAgeAtEffectiveDate(mem.dateOfBirth, group.effectiveDate)
                if((memberAge < 18 || memberAge > 65) && memberAge >= 0){ memberAgesRes.push(<span>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}</a> is {memberAge} year{memberAge !== 1 ? 's' : ''} old.</span>) }
                else if (memberAge === 65){ memberAgesRes.push(<span>❗ <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}</a> is {memberAge} years old.</span>) }
                else if (memberAge <= -2){ memberAgesRes = ([<span>❌ Group has no effective date.</span>]) }
                else if (memberAge <= -1){ memberAgesRes.push(<span>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}</a> DoB is not in the system.</span>) }
                // check dependent ages
                mem.dependents.forEach(dep => {
                    let dependentAge = parseAgeAtEffectiveDate(dep.dateOfBirth, group.effectiveDate)
                    if (dep.relationship === 'child'){
                        if(dependentAge >= 26){ dependentAgesRes.push(<span>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{dep.firstName} {dep.lastName}</a> is {dependentAge} years old.</span>) } 
                    if(dependentAge >= 26){ dependentAgesRes.push(<span>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{dep.firstName} {dep.lastName}</a> is {dependentAge} years old.</span>) } 
                        if(dependentAge >= 26){ dependentAgesRes.push(<span>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{dep.firstName} {dep.lastName}</a> is {dependentAge} years old.</span>) } 
                        else if (dependentAge > 18 && dependentAge < 26){ dependentAgesRes.push(<span>❗ <a href={`#${mem.id}`} className="audit-member-name-anchor">{dep.firstName} {dep.lastName}</a> is {dependentAge} years old.</span>) } 
                    else if (dependentAge > 18 && dependentAge < 26){ dependentAgesRes.push(<span>❗ <a href={`#${mem.id}`} className="audit-member-name-anchor">{dep.firstName} {dep.lastName}</a> is {dependentAge} years old.</span>) } 
                        else if (dependentAge > 18 && dependentAge < 26){ dependentAgesRes.push(<span>❗ <a href={`#${mem.id}`} className="audit-member-name-anchor">{dep.firstName} {dep.lastName}</a> is {dependentAge} years old.</span>) } 
                        else if (dependentAge === 0){ dependentAgesRes.push(<span>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{dep.firstName} {dep.lastName}</a> is 0 years old.</span>) }
                        else if (dependentAge <= -2){ dependentAgesRes = ([<span>❌ Group has no effective date.</span>]) }
                        else if (dependentAge <= -1){ dependentAgesRes.push(<span>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{dep.firstName} {dep.lastName}</a> DoB is not in the system.</span>) }
                    }
                })
            }
            // check member location
            if(mem.status === 'enrolled' && !group.states.includes(mem.state)){ 
                memberLocationsRes.push(<span className='audit-bullet-indent'>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}</a> is located in {mem.state}. Enrollment completed</span>) 
                memberLocationIssues++
            }
            if(mem.status === 'inProgress' && !group.states.includes(mem.state) && mem.state !== ""){ 
                memberLocationsRes.push(<span className='audit-bullet-indent'>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}</a> is located in {mem.state}. Has not finished enrollment.</span>)
                memberLocationIssues++
            }
            if(mem.state === undefined || mem.state === ""){ 
                memberLocationsRes.push(<span className='audit-bullet-indent'>- <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}</a> has not completed 'basic info' page in EE shop, no state found.</span>)
                memberLocationIssues++
            }
            // check member counties/fips codes
            let memberCountyData = auditData.counties.filter(i => i.memberID === mem.id)[0]
            if (memberCountyData.fips.length < 1) {
                memberCountiesRes.push(`❌ ${mem.name} does not have a FIPS code.`) 
                membersWithMultCounties++
            } else if (memberCountyData.fips.length > 1) {
                memberCountiesRes.push([<span className='audit-bullet-indent'>- <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}'s</a> ZIP code ({memberCountyData.zip}) is a part of {memberCountyData.fips.length} counties:</span>, <span style={{paddingLeft: '27px'}}>{memberCountyData.fips.map(fip => ` ${fip.county} (${fip.fips})`)}</span>])
                membersWithMultCounties++
            }
            // check member medical underwriting for: 'All State'
            if((mem.plan !== undefined && mem.plan.carrier === "allstate") || !mem.medical_underwriting_complete){ 
                memberUnderwritingRes.push(<span className='audit-bullet-indent'>- <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}'s</a> medical underwriting is not complete.</span>) 
            } 
            else if (mem.plan === undefined){ 
                memberUnderwritingRes.push(<span className='audit-bullet-indent'>- <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}</a> has not selected a medical plan.</span>) 
            }
            else {
                membersWithUnderwriting++
            }
            // check visited stargate
            if(!memberStargateEmails.includes(mem.email)){ memberStargateRes.push(<span className='audit-bullet-indent'>- <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}</a> has not visited the shop.</span>) } else membersVisitedStargate++
            // check member planIDs in new_group_plans
            let memberPlanIDs = []
            if(mem.plan){memberPlanIDs.push(mem.plan.id)}
            if(mem.enrolled_dental_plan_id){memberPlanIDs.push(mem.enrolled_dental_plan_id)}
            if(mem.enrolled_vision_plan_id){memberPlanIDs.push(mem.enrolled_vision_plan_id)}
            if(mem.enrolled_life_plan_id){memberPlanIDs.push(mem.enrolled_life_plan_id)}
            if(mem.accident){memberPlanIDs.push(mem.accident.plan.id)}
            if(mem.cancer){memberPlanIDs.push(mem.cancer.plan.id)}
            if(mem.criticalIllness){memberPlanIDs.push(mem.criticalIllness.plan.id)}
            if(mem.hospital){memberPlanIDs.push(mem.hospital.plan.id)}
            if(mem.std){memberPlanIDs.push(mem.std.plan.id)}
            memberPlanIDs.forEach(planID => {
                if(!newGroupPlanIDs.includes(planID)){
                    memberPlanIDRes.push(<span>❌ <a href={`#${mem.id}`} className="audit-member-name-anchor">{mem.name}'s</a> planID: {planID} is not within the group's plans.</span>)
                }
            })
        });
        if(members.length === membersWithUnderwriting){
            memberUnderwritingRes.push(`✅ ${membersWithUnderwriting} of ${members.length} members have completed underwriting.`)
        } else {
            memberUnderwritingRes.unshift(<span><strong>❗ {(members.length - membersWithUnderwriting)} of {members.length} members have not completed underwriting.</strong></span>)
        }
        if(members.length === membersVisitedStargate){
            memberStargateRes.push(`✅ ${membersVisitedStargate} of ${members.length} members have visited the shop.`)
        } else {
            memberStargateRes.unshift(<span><strong>❗ {(members.length - membersVisitedStargate)} of {members.length} members have not visited the shop.</strong></span>)
        }
        if(memberCountiesRes.length !== 0) memberCountiesRes.unshift(<span><strong>❗ {membersWithMultCounties} of {members.length} Members with {membersWithMultCounties === 1 ? 'a' : ''} ZIP code{membersWithMultCounties !== 1 ? 's' : ''} that fall within multiple county codes</strong></span>)
        if(memberLocationsRes.length !== 0) memberLocationsRes.unshift(<span><strong>❗ {memberLocationIssues} of {members.length} Members have issues with their location or have not completed the basic info page</strong></span>)

        let newState = { ...state }
        newState.memberAges.response = memberAgesRes.length > 0 ? memberAgesRes : ["✅"]
        newState.dependentAges.response = dependentAgesRes.length > 0 ? dependentAgesRes : ["✅"]
        newState.memberUnderwriting.response = memberUnderwritingRes
        newState.memberCountyCodes.response = memberCountiesRes.length > 0 ? memberCountiesRes : ["✅"]
        newState.groupFipsCodes.category = [`Group ZIP code: ${group.zipCode}`, `Group Assigned FIPS code: ${group.countyID} (${group.countyName})`] // update for each subsequent dump
        newState.groupFipsCodes.response = [`${group.fipsCodes.length > 1 ? "❗ Multiple" : group.fipsCodes.length < 1 ? "❌ No" : "Only"} possible FIPS code: ${group.fipsCodes.map(fip => ` ${fip.fips} (${fip.county})`)}`]
        newState.memberLocation.category = [`Members located in group state${group.states.length > 1 ? "s" : ""} (${group.states.join(", ")})`] // update for each subsequent dump
        newState.memberLocation.response = memberLocationsRes.length > 0 ? memberLocationsRes : ["✅"]
        newState.memberPlanIDs.response = memberPlanIDRes.length > 0 ? memberPlanIDRes : ["✅"]
        newState.memberInvites.response = memberInvites ? ["✅"] : ["❌ Member invites not sent"]
        newState.managerInvite.response = managerInvites ? ["✅"] : ["❌ Manager invite not sent"]
        newState.memberStargate.response = memberStargateRes
        if (groupHasEffectiveDate) { newState.groupEffectiveDate.response = group.group.pipelineStatus !== "enrolled" ? parseEffectiveDate(group.group.effectiveDate) : [`✅ Group is enrolled. Effective date -> ${parseUTC(group.group.effectiveDate)}`] }
        if(invites_deleted.every(invite => invite != null)) newState.memberInvites.response = ["❌ All Member invites have been deleted"] // checks if all invite emails were deleted
        setState(newState)
        
        setShowAuditor(true)
    }

    const showHideButtons = (key) => {
        setState({
            ...state,
            ...state[key[0]].visible = !state[key[0]].visible
        })
    }

    useEffect(() => {
        checkMembers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group, members])

    return (
        <div>
            <div className='section-header'>
                <h3>Group Auditor</h3>
                <button onClick={() => setShowAuditor(!showAuditor)} className="btn btn-success show-hide-button" >{showAuditor ? "hide audit" : "show audit"}</button>
            </div>
            {showAuditor ? <>
                <div>
                    <span style={{paddingRight: "15px"}}>✅ pass</span>
                    <span style={{paddingRight: "15px"}}>❌ fail</span>
                    <span style={{paddingRight: "10px"}}>❗issue</span>
                </div>
                <table className='audit-table'>
                    <thead>  
                        <tr className='text-primary audit-table-header'>
                            <th className="audit-table-cell">Category</th>
                            <th className="audit-table-cell">Response</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(state).map((row, index) => {
                            return(<tr key={index}>
                                <td className="audit-table-cell">{row[1].category.map((res, index) => <span className='audit-table-span' key={index}>{res}</span>)}</td>
                                <td className="audit-table-cell">{row[1].response.map((res, i) => <span className='audit-table-span repsonse' key={i} style={{display: `${(row[1].visible === true || i === 0) ? 'block' : 'none'}`}}>
                                    { Array.isArray(res) ? res.map((str, index) => <div key={index}>{str}</div> ) : 
                                        <div>{res} {i === 0 && row[1].button && row[1].response.length > 1 && <button className='audit-button-show-hide' onClick={() => showHideButtons(row)}>{row[1].visible ? 'hide' : 'show'}</button>}</div> }
                                </span>)}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </> : <></>}
        </div>
    )
}
