import moment from 'moment'

const phoneFormatter = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }
  return null
}

const einNormalizer = value => {
  if (!value) {
    return value;
  } else {
    value = value.replace(/[^\d]/g, '');
  }
  return value.length > 2 ? `${value.substring(0, 2)}-${value.substring(2)}` : value
}

const ssnNormalizer = value => {
  if (!value) {
    return value;
  } else {
    value = value.replace(/[^\d]/g, '');
  }
  return value.length > 3 ? value.length > 5 ? `${value.substring(0, 3)}-${value.substring(3,5)}-${value.substring(5)}` : `${value.substring(0, 3)}-${value.substring(3)}` : value
}

const phoneNormalizer = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`
}

const phoneOnlyNumberizer = value => {
  if (!value) return value;
  value = value.replace(/[^\d]/g, '')
  if (!value) return null;
  return value;
}

const formatDate = date => {
  return date ? moment(date).format('YYYY-MM-DD') : null
}

const getDateTZ = (date, format = null) => {
  // I'm assuming the point here is to get a stable UTC/ISO8601 that will
  // represent the date selected across all US timezones. This means
  // date>startOfDay>+12 hours (for safety)
  return format
    ? moment(date || {}).startOf('day').add(12, 'hours').format(format)
    : moment(date || {}).startOf('day').add(12, 'hours').toDate()
}

const formatDateForHumans = date => {
  return getDateTZ(date, 'MM/DD/YYYY')
}

// Format for pharaoh + UTC timezone
const formatDateISO8601 = date => {
  // stripping out milliseconds. Pharaoh doesn't like that, specifically (yaslab/CSV.swift)
  return date ? moment(date).toISOString().replace(/\.[0-9]{3}Z$/, 'Z') : null;
}

const hashString = str => {
  // should probably use `return crypto.subtle.digest('SHA-1', str)` or similar.
  var hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
    hash = Math.abs(hash); // no negatives (probably won't collide lol)
  }
  return hash.toString();
}

export {
  phoneFormatter,
  hashString,
  phoneOnlyNumberizer,
  phoneNormalizer,
  formatDate,
  formatDateISO8601,
  formatDateForHumans,
  getDateTZ,
  einNormalizer,
  ssnNormalizer
}
