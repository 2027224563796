import React, { useState } from 'react';
import api from '../../utils/supportApi'
import errorAlert from '../../utils/alert'
import TableRender from '../../utils/TableRender'
import queryString from 'query-string'
import { moneyString } from '../../utils/PremiumCalculators'
import moment from 'moment'

type SidecarAccount = {
    accountUuid: string
    primary: SidecarPerson
    dependents?: [SidecarPerson]
}

type SidecarPerson = {
    memberUuid: string
    firstName: string
    lastName: string
    gender: 'FEMALE' | 'MALE'
    primary: boolean
    policyState: string
    enrollmentStatus: 'ENROLLED' | 'NOT_COVERED' | 'PENDING_PURCHASE'
    policyId: string
    policyUuid: string
    policyStatus: 'ENROLLED' | 'SUSPENDED' | 'CANCELLED' | 'PENDING_CANCELLATION' | 'EXPIRED' | 'UPCOMING' | 'SUSPENDED_PENDING_REINSTATEMENT'
    policyCoverageStatus: 'ACTIVE' | 'PENDING_APPROVAL' | 'PENDING_ACCEPTANCE' | 'PENDING_ACTIVATION' | 'PENDING_PAYMENT'
    startDate: Date
    policyStartDate: Date
    policyEndDate: Date
    currentBillingCycleStartDate: Date
    currentBillingCycleEndDate: Date
    nextBillingCycleDate: Date
    createdAt: Date
    monthlyCost: number
    groupCost: number
    memberCost: number
}

const SidecarAccounts: React.FC = () => {
    const groupUuid = queryString.parse(window.location.search).sidecar
    const [accounts, setAccounts] = useState<[SidecarAccount]>()
    moment.defaultFormat = 'M/D/YY'

    if (groupUuid && !accounts) {
        api.sidecarAccounts(groupUuid).then(rsp => setAccounts(rsp.accounts)).catch(errorAlert)
    }

    function mapPerson(person: SidecarPerson) {
        return [
            person.memberUuid,
            `${person.firstName} ${person.lastName}`,
            person.primary ? 'YES' : 'no',
            person.gender,
            person.policyState,
            person.enrollmentStatus,
            person.policyId,
            person.policyUuid,
            person.policyStatus,
            person.policyCoverageStatus,
            moment(person.startDate).format(),
            moment(person.policyEndDate).format(),
            moment(person.nextBillingCycleDate).format(),
            `${person.monthlyCost}`,
            `${moneyString(person.groupCost)} / ${moneyString(person.memberCost)}`
        ]
    }

    function mangleAccounts(accounts: [SidecarAccount]) {
        return [
            ['Sidecar UUID', 'Name', 'Employee?', 'Gender', 'State', 'Enrollment Status', 'Policy ID', 'Policy UUID', 'Policy Status', 'Coverage Status', 'Start Date', 'End Date', 'Next Bill Date', 'Cost', 'ER/EE'],
            ...accounts.flatMap((account: SidecarAccount) => {
                let member = [mapPerson(account.primary)]
                if (account.dependents && account.dependents?.length > 0) {
                    member.concat(account.dependents.map(d => mapPerson(d)))
                }
                return member
            })
        ]
    }

    return accounts ? <TableRender rows={mangleAccounts(accounts)} /> : <></>
}

export default SidecarAccounts