import React, { useState } from 'react';
import api from '../../utils/supportApi'
import errorAlert from '../../utils/alert'
import TableRender from '../../utils/TableRender'
import { moneyString } from '../../utils/PremiumCalculators'

type SidecarGroup = {
    uuid: string
    name: string
    urlSlug: string
    status: 'ACTIVE'
    paidBy: 'GROUP' | 'MEMBER'
    primaryContribution: number
    primaryContributionType: 'PERCENT' | 'DOLLAR'
    dependentContribution?: number
    dependentContributionType?: 'PERCENT' | 'DOLLAR'
  }

const SidecarGroups: React.FC = () => {
    const [groups, setGroups] = useState<[SidecarGroup]>()

    async function load() {
        api.sidecarGroups().then(rsp => setGroups(rsp.groups)).catch(errorAlert)
    }

    function mangleGroups(groups: [SidecarGroup]) {
        return [
            ['Sidecar UUID', 'Name', 'URL Slug', 'Status', 'Paid By', 'EE Contribution', 'Dep Contribution'],
            ...groups.map((group: SidecarGroup) => [
                <a href={`?sidecar=${group.uuid}`}>{group.uuid}</a>,
                group.name,
                group.urlSlug,
                group.status,
                group.paidBy,
                group.primaryContribution && (group.primaryContributionType === 'DOLLAR' ? moneyString(group.primaryContribution) : `${group.primaryContribution}%`),
                group.dependentContribution && group.dependentContributionType && (group.dependentContributionType === 'DOLLAR' ? moneyString(group.dependentContribution) : `${group.dependentContribution}%`)
            ])
        ]
    }

    return groups && groups.length >= 1
        ? <TableRender rows={mangleGroups(groups)} />
        : <button className='btn btn-primary' onClick={load}>Load</button>
}

export default SidecarGroups