import React from 'react'
import TableRender from '../../utils/TableRender'

export default function GroupDumpSplits(props) {
  if (!props.splits || props.splits.length <= 0) return <p>—</p>;

  const splitRows = () => {
    const splitRows = props.splits.map(split => mangleSplit(split));
    const keys = Object.keys(splitRows[0]);
    const rows = [keys];
    splitRows.forEach(row => rows.push(keys.map(key => row[key])))
    return rows;
  }

  return <TableRender rows={splitRows()} />
}

function mangleSplit(split) {

  let members;
  if (split.members) {
    members = <ul className="list-group">{
      split.members.map(member => <li className="list-group-item" key={member.id}><a href={`#${member}`}>{member}</a></li>)
    }</ul>
  }

  return {
    "Name": split.name || '–',
    "Contribution": split.contribution || '–',
    "Is Equitable": (split.isEquitable || false).toString(),
    "Members": members || '–',
    "ID": split.id || '–',
  }
}
